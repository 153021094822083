<template>
	<div class="login-box">
		<div class="title">{{step?'请输入绑定的手机号':'请填写新密码'}}</div>
		<!-- 第一步、输入绑定的手机号 -->
		<el-form v-if="step" ref="firstStepRef" :model="firstStepForm" :rules="firstRules" size="large" class="u-m-t-15">
			<el-form-item prop="memberPhone">
				<el-input v-model="firstStepForm.memberPhone" type="text" :prefix-icon="User" placeholder="请输入手机号"></el-input>
			</el-form-item>
			<el-form-item prop="verificationCode">
				<el-input v-model="firstStepForm.verificationCode" :prefix-icon="CircleCheck" type="text"
					placeholder="请输入验证码"  :class="{'input-width-70':!sms.disabled,'input-width-60':sms.disabled}"></el-input>
				<el-button v-if="!sms.disabled" type="primary" @click="sendCode" style="margin-left:10px !important;">发送验证码</el-button>
				<el-button v-else type="primary" disabled style="margin-left:10px !important;">{{ sms.count }} 秒后重新发送</el-button>
			</el-form-item>
			<div class="u-center">
				<el-button class="sumbit-btn" type="primary" @click="submitFirstForm(firstStepRef)">下一步</el-button>
				<div class="tips">温馨提示：请输入绑定的手机号！</div>
			</div>
		</el-form>
		<!-- 第二步、填写新密码 -->
		<el-form v-else ref="secondStepRef" :model="secondStepForm" :rules="secondRules" size="large" class="u-m-t-15">
			<el-form-item prop="password">
				<el-input v-model="secondStepForm.password" type="password" :prefix-icon="Lock" placeholder="请输入新密码"
					clearable></el-input>
			</el-form-item>
			<el-form-item prop="confirmPassword">
				<el-input v-model="secondStepForm.confirmPassword" :prefix-icon="Lock" type="password" placeholder="请再次输入新密码"
					clearable></el-input>
			</el-form-item>
			<div class="u-center">
				<el-button class="sumbit-btn" type="primary" @click="submitPasswordForm(secondStepRef)">确定</el-button>
			</div>
		</el-form>
	</div>
</template>

<script setup>
	import {ref,reactive} from "vue";
	import {User,CircleCheck,Lock} from '@element-plus/icons-vue'
	import {ElMessage} from 'element-plus';
	import {useRouter} from 'vue-router';
	import {memberForgetPassword,sendResetPwdCode,memberCheck} from '@/api/user';
	
	const router = useRouter();
	const step = ref(true);
	
	//第一步，输入手机
	const firstStepRef = ref();
	const firstStepForm = reactive({
		memberPhone: '',
		verificationCode: ''
	});
	const firstRules = reactive({
		memberPhone: [{
			required: true,
			message: '请输入手机号',
			trigger: 'blur'
		}, {
			pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
			message: '手机号码格式不正确',
			trigger: 'blur'
		}],
		verificationCode: [{
			required: true,
			message: '请输入验证码',
			trigger: 'blur'
		}]
	});
	
	// 发送短信验证码
	const sendCode = () => {
		sendResetPwdCode({memberPhone:firstStepForm.memberPhone}).then(() => {
			timerHandler()
		})
	}
	
	// 短信计时器
	const sms = reactive({
		disabled: false,
		total: 300,
		count: 0
	})
	
	// 计时器处理器
	const timerHandler = () => {
		sms.count = sms.total
		sms.disabled = true
	
		let timer = setInterval(() => {
			if (sms.count > 1 && sms.count <= sms.total) {
				sms.count--
			} else {
				sms.disabled = false
				clearInterval(timer)
			}
		}, 1000)
	}
	//会员重设密码时的“下一步”校验接口
	const submitFirstForm = (formE1) => {
		formE1.validate((valid, fields) => {
			if (valid) {
				memberCheck(firstStepForm).then(res => {
					step.value = false;
				})
			} 
		})
	}
	
	//步骤二、重置密码
	const secondStepRef = ref();
	
	const secondStepForm = reactive({
		password: '',
		confirmPassword: ''
	});
	
	//验证两次密码是否一致
	const validatePassword = (rule, value, callback) => {
		if (value !== secondStepForm.password && secondStepForm.password !== '') {
			callback(new Error("两次输入的密码不一致"));
		} else {
			callback();
		}
	};
	
	const secondRules = reactive({
		password: [{
			required: true,
			message: '请输入新密码',
			trigger: 'blur'
		},{
			pattern: /^[0-9a-zA-Z_]{6,16}$/,
			message: '密码应由0-9,a-z,-_任意组合6-16个的字符串',
			trigger: 'blur'
		}
		],
		confirmPassword: [{
			required: true,
			message: '请再次输入新密码',
			trigger: 'blur'
		},{
			required: true,
			validator: validatePassword,
			message: '两次输入的密码不一致',
			trigger: "blur"
		}
		]
	});
	const submitPasswordForm = (formE2) => {
		formE2.validate((valid, fields) => {
			if (valid) {
				let password = {
					memberPhone:firstStepForm.memberPhone,
					pwd:secondStepForm.password,
					confirmPwd:secondStepForm.confirmPassword
				};
				memberForgetPassword(password).then(res => {
					ElMessage({
						message: '密码修改成功',
						type: 'success',
						duration:1000
					});
					setTimeout(() => {
						router.push({
							path: '/login'
						})
					}, 2000);
				})
			} 
		})
	}	
</script>

<style lang="scss" scoped>
	@import '@/assets/style/login.scss';
	
	.title {
		font-size: 24px;
		font-weight: 400;
		color: #333333;
		line-height: 48px;
		//border-bottom: 1px solid rgba(224, 228, 235, 1);
		padding: 30px 0px;
		text-align: center;
		margin-bottom: 30px;
	}
	
	.tips {
		margin: 50px 0px;
		font-size: 18px;
		font-weight: 400;
		color: #39A802;
	}
	.input-width-60{width:60% !important;}
	.input-width-70{width:70% !important;}
</style>