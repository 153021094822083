<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="竞购结果" width="900" :draggable="true">
		<!-- 搜索栏 -->
		<el-form ref="formRef" :model="params" style="display: flex;">
			<el-form-item label="日期：" class="u-m-r-15">
				<el-date-picker v-model="params.saleDate" type="date" placeholder="请选择竞购日期" format="YYYY-MM-DD"
					:clearable="false" @change="params.saleDate = moment($event).format('YYYY-MM-DD')" />
			</el-form-item>
			<el-form-item class="u-m-r-15">
				<el-select v-model="params.isMine">
					<el-option label="所有人" :value="0" />
					<el-option label="我自己" :value="1" />
				</el-select>
			</el-form-item>
			<el-form-item class="u-m-r-15">
				<el-select v-model="params.bidType">
					<el-option label="全部" :value="-1" />
					<el-option label="有低价" :value="1" />
					<el-option label="无低价" :value="2" />
				</el-select>
			</el-form-item>
			<el-form-item class="u-m-r-15">
				<el-select v-model="params.isSuccess">
					<el-option label="全部" :value="-1" />
					<el-option label="竞购成功" :value="1" />
					<el-option label="竞购不成功" :value="0" />
				</el-select>
			</el-form-item>
			<el-form-item class="u-m-r-15">
				<el-input v-model="params.kw" placeholder="请输入品名" clearable></el-input>
			</el-form-item>
			<el-button type="primary" @click="getBidResultList">查询</el-button>
		</el-form>
		<el-table :data="bidProTable" v-loading="loading" border empty-text="没有找到相关信息！" table-layout="auto" size="small" style="width:100%" 
			show-summary :summary-method="getSummaries">
			<el-table-column prop="OrderTime" label="竞购时间" align="center"></el-table-column>
			<el-table-column label="品牌|品名|等级" align="center">
				<template #default="scope">
					<span>{{ scope.row.Brand + scope.row.ProductName + scope.row.Grade }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="Color" label="颜色" align="center"></el-table-column>
			<el-table-column prop="Length" label="长度" align="center"></el-table-column>
			<el-table-column prop="PackCount" label="包装" align="center"></el-table-column>
			<!-- <el-table-column prop="ActualCount" label="实际分配" align="center"></el-table-column> -->
			<el-table-column prop="Price" label="单价" align="center"></el-table-column>
			<el-table-column prop="OrderCount" label="竞购量" align="center" width="60"></el-table-column>
			<el-table-column prop="OrderPrice" label="竞购价" align="center" width="60"></el-table-column>
			<el-table-column prop="ActualCount" label="分配数量" align="center" width="80"></el-table-column>
			<el-table-column prop="RealAmount" label="金额" align="center" width="60"></el-table-column>
			<!-- <el-table-column prop="MemberName" label="竞购用户" align="center" width="60"></el-table-column> -->
			<el-table-column prop="MemberCode" label="会员号" align="center" width="60"></el-table-column>
		</el-table>
		<div class="u-pagination">
			<el-pagination background :current-page="params.limit.conditions.pageStart" :page-size="params.limit.conditions.pageLength" 
				layout="prev, pager, next" prev-text="上一页" next-text="下一页" :total="total" @current-change="handleCurrentChange"
				@size-change="handleSizeChange"></el-pagination>
		</div>
	</el-dialog>
</template>

<script setup>
	import { ref, reactive } from "vue";
	import moment from "moment";
	import {
		getOrderBidResultList
	} from '@/api/productSale.js'

	//自定义合计列，不自定义时会合计产品编码
	const getSummaries = (param) => {
		const {
			columns,
			data
		} = param
		const sums = []
		columns.forEach((column, index) => {
			if (index === 6) {
				sums[index] = '合计'
				return
			}
			if (column.property === 'OrderCount') {
				const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
				sums[index] = values.reduce((prev, curr) => {
					const value = Number(curr) // 将values中的每个值转换为number类型
					if (!isNaN(value)) {
						return prev + curr
					} else {
						return prev
					}
				}, 0)
			}else if (column.property === 'OrderPrice'){
				const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
				sums[index] = values.reduce((prev, curr) => {
					const value = Number(curr) // 将values中的每个值转换为number类型
					if (!isNaN(value)) {
						return prev + curr
					} else {
						return prev
					}
				}, 0)
				sums[index] = sums[index].toFixed(2)
			}else if (column.property === 'RealAmount'){
				const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
				sums[index] = values.reduce((prev, curr) => {
					const value = Number(curr) // 将values中的每个值转换为number类型
					if (!isNaN(value)) {
						return prev + curr
					} else {
						return prev
					}
				}, 0)
				sums[index] = sums[index].toFixed(2)
			}else{
				sums[index] = ''
			}
		})

		return sums
	};

	const params = reactive({
		saleDate: moment().format('YYYY-MM-DD'),
		bidType: -1,
		kw: '',
		isMine: 0,
		isSuccess: -1,
		limit: {							// 分页参数
			enable: true,
			conditions: {
				pageStart: 1,
				pageLength: 15
			}
		}
	})
	const loading = ref(true)
	const total = ref(0)
	const bidProTable = ref([])
	const getBidResultList = async () => {
		loading.value = true
		const { data: { filterAmount, records } } = await getOrderBidResultList(params)
		total.value = filterAmount
		bidProTable.value = records
		loading.value = false
	}

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	 const handleCurrentChange = (val) => {
		params.limit.conditions.pageStart = val
		getBidResultList()
	}
	
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		params.limit.conditions.pageLength = val
		getBidResultList()
	}

	/**
	 * 弹窗显示
	 * @param {无}
	 */
	const dialogVisible = ref(false);
	const onChangeVisible = () => {
		dialogVisible.value = true;
		getBidResultList()
	};

	defineExpose({
		onChangeVisible
	});
</script>

<style lang="scss" scoped>
</style>
