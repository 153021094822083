<template>
	<div class="pwd-wrap">
		<div class="title">修改密码前需要提供原来密码（旧密码）</div>
		<el-form ref="changePwdRef" :model="changePwdForm" :rules="changeRules" label-width="140" size="large" class="u-m-t-15">
			<el-form-item prop="oldPwd" label="请输入旧密码:">
				<el-input v-model="changePwdForm.oldPwd" type="password" placeholder="请输入旧密码"
					clearable></el-input>
			</el-form-item>
			<el-form-item prop="pwd" label="请输入新密码:">
				<el-input v-model="changePwdForm.pwd" type="password" placeholder="请再次输入新密码"
					clearable></el-input>
			</el-form-item>
			<el-form-item prop="confirmPwd" label="请再次输入新密码:">
				<el-input v-model="changePwdForm.confirmPwd" type="password" placeholder="请再次输入新密码"
					clearable></el-input>
			</el-form-item>
			<div class="tips"><span class="u-font-weight">提示：</span>为了确保您的帐号安全，密码请勿使用容易被猜测的数字或字母，例如：1234、ABCD、0000、名字的拼音、出生年月、电话号码、手机号码等。如果您的密码过于简单，登录时系统会自动提示您修改密码，密码请使用数字和字母、符号任意组合6-16个的字符串。</div>
			<div class="tips">密码应由0-9,a-z,-_任意组合6-16个的字符串</div>
			<el-form-item>
				<el-button class="sumbit-btn" type="primary" @click="submitPsdForm(changePwdRef)">确定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script setup>
	import {ref,reactive} from "vue";
	import {Lock} from '@element-plus/icons-vue'
	import {ElMessage} from 'element-plus';
	import {memberSetNewPassword} from '@/api/user';
	import {useRouter} from 'vue-router';
	const changePwdRef = ref();
	const router = useRouter();
	const changePwdForm = reactive({
		oldPwd:'',
		pwd: '',
		confirmPwd: ''
	});
	
	//验证两次密码是否一致
	const validatePassword = (rule, value, callback) => {
		if (value !== changePwdForm.pwd && changePwdForm.pwd !== '') {
			callback(new Error("两次输入的密码不一致"));
		} else {
			callback();
		}
	};
	
	const changeRules = reactive({
		oldPwd: [{
			required: true,
			message: '请输入旧密码',
			trigger: 'blur'
		}],
		pwd: [{
			required: true,
			message: '请输入新密码',
			trigger: 'blur'
		},{
			pattern: /^[0-9a-zA-Z_]{6,16}$/,
			message: '密码应由0-9,a-z,-_任意组合6-16个的字符串',
			trigger: 'blur'
		}
		],
		confirmPwd: [{
			required: true,
			message: '请再次输入新密码',
			trigger: 'blur'
		},{
			required: true,
			validator: validatePassword,
			message: '两次输入的密码不一致',
			trigger: "blur"
		}
		]
	});
	const submitPsdForm = (form) => {
		form.validate((valid, fields) => {
			if (valid) {
				memberSetNewPassword(changePwdForm).then(res => {
					ElMessage({
						message: '密码修改成功',
						type: 'success',
						duration:1000
					});
					setTimeout(() => {
						router.push({
							path: '/login'
						})
					}, 2000);
				})
			} 
		})
	}	
</script>

<style lang="scss" scoped>
	.pwd-wrap{
		width:665px;
		.title{
			font-size: 18px;
			font-weight: 500;
			color: #363636;
			line-height: 44px;
			font-weight: bold;
		}
		.el-input__prefix{
			font-size:22px;
		}
		.el-input__wrapper{
			height:40px;
			line-height:40px;
		}
		.tips{
			font-size: 17px;
			font-weight: 500;
			line-height: 30px;
			color: #676767;
			.u-font-weight{
				color: #FF3232;
				font-weight: bold;
			}
		}
		.sumbit-btn{
			margin-top:50px;
			width: 140px;
			height: 42px;
			line-height:42px;
			border-radius: 20px;
		}
	}
</style>