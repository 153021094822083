<template>
  <div class="btn-wrap">
    <el-button type="primary" @click="onlineMessageRef.onChangeVisible()">用户留言</el-button>
    <el-button type="primary" @click="messageRef.onDialogVisible()">查看留言</el-button>
    <el-button type="primary" @click="biddingProductRef.onChangeVisible()">竞购产品</el-button>
    <el-button type="primary" @click="biddingResultRef.onChangeVisible()">竞购结果</el-button>
    <el-button type="primary" @click="customerManagerRef.dialogVisible = true">客户经理</el-button>
    <el-button type="primary" @click="rechargeRef.onChangeVisible()">在线充值</el-button>
    <el-button type="success" @click="memberUpgradeRef.onChangeVisible()">VIP升级</el-button>
  </div>
  <!-- 竞购产品 -->
  <bidProductDialog ref="biddingProductRef"></bidProductDialog>
  <!-- 竞购结果 -->
  <bidResultDialog ref="biddingResultRef"></bidResultDialog>
  <!-- 我的客户经理 -->
  <customerManagerDialog ref="customerManagerRef"></customerManagerDialog>
  <!-- 用户留言 -->
  <leaveAMassage ref="messageRef"></leaveAMassage>
  <!-- 查看留言 -->
  <orderLeaveAMessage ref="onlineMessageRef"></orderLeaveAMessage>
  <!-- 会员充值 -->
  <memberRechargeDialog ref="rechargeRef"></memberRechargeDialog>
  <!-- vip升级 -->
  <memberUpgradeDialog ref="memberUpgradeRef"></memberUpgradeDialog>
</template>

<script setup>
import bidProductDialog from '@/components/bid-product/index.vue'
import bidResultDialog from '@/components/bid-result/index.vue'
import customerManagerDialog from '@/components/customer-manager/index.vue'
import memberRechargeDialog from '@/components/recharge-modal/index.vue'
import memberUpgradeDialog from '@/components/member-upgrade/index.vue'
import leaveAMassage from '@/components/leave-a-message/index.vue'
import orderLeaveAMessage from '@/components/order-leave-a-massage/index.vue'
import { ref } from 'vue'

const biddingProductRef = ref();
const biddingResultRef = ref();
const customerManagerRef = ref();
const onlineMessageRef = ref()
const messageRef = ref();
const rechargeRef = ref();
const memberUpgradeRef = ref();
</script>

<style lang="scss" scoped>
.btn-wrap {
  margin: 0px 5px 0px 15px;

  .el-button {
    display: inline-block;
    width: 80px;
    margin-right: 8px !important;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 8px;
  }

}

.el-form--label-top .el-form-item .el-form-item__label {
  font-size: 18px;
  color: #333333;
  font-weight: 400;
  padding: 15px 0px;
}

.el-form-item__error {
  padding-bottom: 10px;
}
</style>
