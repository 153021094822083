import {
	defineStore
} from 'pinia';
import {
  shoppingCartList
} from '@/api/cart'
import moment from 'moment';

export const cartStore = defineStore('cartStore', {
  persist: {
		enabled: true, // 开启数据持久化
	},
  state: () => ({
    cartList: [],   // 购物车列表
    total: 0        // 总数量
  }),
  actions: {
    // 取购物车列表
    async getCartList() {
      console.trace('getCartList')
      const { data: { code,data: { filterAmount, records } } } = await shoppingCartList()
      if (code == 0) {
        this.cartList = records.map(item => {
          // 过了当天12点自动过期商品
          const isGreaterThanTwelve = moment().format('HH') >= 12 && moment().format('YYYY-MM-DD') >= moment(item.SaleDate).format('YYYY-MM-DD')
          return {
            ...item,
            checked: false,
            isOverdue: isGreaterThanTwelve || moment().format('YYYY-MM-DD') > item.SaleDate || item.IsStock == 0  // 是否过期时间或是否无库存
          }
        })
        this.total = filterAmount
      }
    }
  }
})