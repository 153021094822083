<template>
	<div class="title">账单查询</div>
	<!-- 搜索栏 -->
	<el-form ref="formRef" :model="searchForm" inline>
		<el-form-item label="交易类型：" prop="relateOrderType">
			<el-select v-model="searchForm.relateOrderType" filterable placeholder="请选择交易类型" @change="changeInputVal" clearable>
				<el-option v-for="item in relateOrderTypeOptions" :key="item.value" :label="item.RelateOrderType"
					:value="item.RelateOrderType" />
			</el-select>
		</el-form-item>
		<el-form-item label="交易日期：" prop="transactionDate">
			<el-date-picker v-model="searchForm.transactionDate" type="daterange" start-placeholder="开始时间"
				end-placeholder="结束时间" range-separator="至" :default-value="[new Date()]" clearable
				@change="change(searchForm.transactionDate)" />
		</el-form-item>
		<el-form-item label="备注：" prop="remark">
			<el-input v-model="searchForm.remark" placeholder="请输入备注" style="width:120px;" clearable @change="changeInputVal"></el-input>
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="getFundRecordList">查询</el-button>
		</el-form-item>
	</el-form>
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="TransactionDate" label="日期" align="center" width="140"></el-table-column>
		<!-- <el-table-column prop="billNo" label="单号" align="center"></el-table-column> -->
		<el-table-column prop="RelateOrderType" label="交易类型" align="center"></el-table-column>
		<el-table-column prop="InFlow" label="收入" align="center"></el-table-column>
		<el-table-column prop="Consume" label="支出" align="center"></el-table-column>
		<el-table-column prop="Balance" label="实际余额" align="center">
			<template #default="scope">
				<span>{{ scope.row.Balance.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="FrozenAmount" label="冻结金额" align="center">
			<template #default="scope">
				<span>{{ scope.row.FrozenAmount.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="AvailableAmount" label="可用余额" align="center">
			<template #default="scope">
				<span>{{ scope.row.AvailableAmount.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="Remark" label="备注" align="center" width="220"></el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted
	} from 'vue';
	import {
		myFundRecordList,getRelateOrderType
	} from '@/api/user';
	import moment from 'moment'
	onMounted(async () => {
		await initData();
	})

    //交易类型
	const relateOrderTypeOptions = ref([]);
	// 获取交易类型列表
	const getSelectRelateOrderType = () => {
		return getRelateOrderType({}).then(res => {
			relateOrderTypeOptions.value = res.data.records;
		})
	}
	
	//搜索
	const searchForm = reactive({
		relateOrderType: '',
		transactionDate: '',
		startTime:'',
		endTime:'',
		remark:''
	});
	//数据源
	const state = reactive({
		params: {
			filters: {
				enable: true,
				join:'and',
				conditions: []
			},
			limit: {
				enable: true,
				conditions: {
					pageLength: 15,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})
	
	const changeInputVal=()=>{
		getFundRecordList()
	}
	
	const change = (val) => {
		if (val) {
			const v = JSON.parse(JSON.stringify(val))
			searchForm.startTime = moment(v[0]).format('YYYY-MM-DD')
			searchForm.endTime = moment(v[1]).format('YYYY-MM-DD')
			
		} else {
			searchForm.startTime = ''
			searchForm.endTime = ''
			getFundRecordList()
		}
		
	}
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getSelectRelateOrderType()
		await getFundRecordList()
	}
	/**
	 * 获取账单查询列表
	 * @param {无}
	 */
	const getFundRecordList = () => {
		state.params.filters.conditions = [];
		state.loading = true
		state.params.filters.conditions.push({
			name:'RelateOrderType',
			type:'like',
			value: searchForm.relateOrderType
		},
		{
			name:'TransactionDate',
			type:'dateBetween',
			value:'[\"'+moment(searchForm.startTime).format('YYYY-MM-DD')+'\",\"'+moment(searchForm.endTime).format('YYYY-MM-DD')+'\"]'
		},
		)
		if(searchForm.remark){
			state.params.filters.conditions.push(
			{
				name:'Remark',
				type:'like',
				value: searchForm.remark
			})
		}
		return myFundRecordList(state.params).then(res => {
			console.log('state.params', state.params)
			const {
				data: {
					filterAmount,
					records
				}
			} = res
			state.data = records.map(item => {
				return {
					...item
				}
			})
			state.total = filterAmount
			state.loading = false
		})
	}

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getFundRecordList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getFundRecordList()
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #3A3A3A;
		line-height: 55px;
	}
</style>