<template>
	<div class="suspend-box" v-if="userInfo.memberInfo">
		<div class="suspend" v-if="userInfo.memberInfo.memberName">
			<el-badge :value="cartInfo.total">
				<i class="iconfont icon-gouwuchefill" @click="goCart"></i>
			</el-badge>
			<p>购物车</p>
		</div>
		<!-- <div class="customerService">
			<i class="iconfont icon-kefunv"></i>在线客服
		</div> -->
	</div>
</template>

<script setup>
	import {
		useRouter
	} from "vue-router";
	import { userStore } from '@/store/user';
	import { cartStore } from "@/store/cart";
	const userInfo = userStore();
	const cartInfo = cartStore()
	const router = useRouter();

	/**
	 * 跳转购物车
	 * @param {无}
	 */
	const goCart = () => {
		const url = router.resolve('/shoppingCart')
		window.open(url.href, '_self') // 跳转新页面栏目
	}
</script>

<style lang="scss" scoped>
	.suspend-box {
	
		.suspend {
			padding: 15px 20px;
			position: fixed;
			bottom: 20px;
			right: 20px;
			font-size: 22px;
			width: 30px;
			background: #FFFFFF;
			box-shadow: 0px 3px 5px 3px rgba(187, 187, 187, 0.35);
			opacity: 0.89;
			border-radius: 4px;
			text-align: center;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.customerService {
			margin-top: 15px;
		}

		.icon-gouwuchefill,
		.icon-kefunv {
			font-size: 40px;
		}

		.icon-kefunv {
			color: #FE5BA3;
		}
	}
</style>
