<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="申请提现" draggable width="400px" @close="closeDialog">
		<el-form  :model="withdrawApplyForm" ref="withdrawApplyFormRef" label-width="70px" label-position="top">
			<el-form-item  class="myMoney" label="申请金额:" prop="amount" :rules="[{ required: true, message: '请填写申请金额',trigger: 'blur' }]">
				<el-input-number v-model="withdrawApplyForm.amount" :precision="2" :controls="false" style="width:100%;text-align: left;"></el-input-number>
			</el-form-item>
			<div class="u-label monetary">
				<div>可提现金额：<span class="font-bold">{{withdrawApplyForm.monetary.toFixed(2)}}</span>元</div>
				<el-button text type="primary" @click="handleSelectAll" style="padding:0px;height: 0px;">全部提现</el-button>
			</div>
			<div class="u-m-b-15 u-m-t-15">
				<el-divider content-position="left">银行卡信息</el-divider>
			</div>
			<el-form-item label="账户姓名(需与预留联系人姓名一致):" prop="accountName"  :rules="[{ required: true, message: '请填写账户姓名',trigger: 'blur' }]">
				<el-input type="text" disabled v-model="withdrawApplyForm.accountName" clearable placeholder="请填写账户姓名" />
			</el-form-item>
			<el-form-item label="银行名称:" prop="bank" :rules="[{ required: true, message: '请填写银行名称',trigger: 'blur' }]">
				<el-input type="text" v-model="withdrawApplyForm.bank" clearable placeholder="请填写银行名称" />
			</el-form-item>
			<el-form-item label="支行名称:" prop="subbranch" :rules="[{ required: true, message: '请填写支行名称',trigger: 'blur' }]">
				<el-input type="text" v-model="withdrawApplyForm.subbranch" clearable placeholder="请填写支行名称" />
			</el-form-item>
			<el-form-item label="银行账号:" prop="defaultAccount" :rules="[{required: true,message: '银行账号不能为空',trigger: 'blur'}, {
			pattern: /^([1-9]{1})(\d{11}|\d{12}|\d{13}|\d{14}|\d{15}|\d{16}|\d{17}|\d{18}|\d{19}|\d{20}|\d{21})$/,
			message: '请输入正确的银行账号',
			trigger: 'blur'}]">
				<el-input type="text" v-model="withdrawApplyForm.defaultAccount" clearable placeholder="请填写银行账号" />
			</el-form-item>
			<div class="tips-wrap">
				<div>提示：</div>
				<div>跨行转账需支付5元跨行手续费。实际到账为申请金额减去5元。提交申请后，2-3个工作日汇款到账。</div> 
			</div>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="large" type="primary" @click="applyWithdraw(withdrawApplyFormRef)">提交申请</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {reactive,ref} from 'vue';
	import {ElMessage} from 'element-plus'
	import {handlerImgUrl} from "@/common/index";
	import {withdrawApply,getMemberInfo} from '@/api/user';
	const emit = defineEmits(['update'])
	// 获取表单对象
	const withdrawApplyFormRef=ref();
	const withdrawApplyForm=reactive({
		objectType:1,
		amount:0,
		monetary:0,
		accountName:'',
		bank:'',
		subbranch:'',
		defaultAccount:''
	});
	
	//获取会员余额和银行卡信息
	const getMemberMonetary=async()=>{
		const {data} = await getMemberInfo({});
		withdrawApplyForm.monetary=data.memberInfo.monetary;
		withdrawApplyForm.accountName=data.memberInfo.linkMan;
		withdrawApplyForm.bank=data.memberInfo.bank;
		withdrawApplyForm.subbranch=data.memberInfo.subbranch;
		withdrawApplyForm.defaultAccount=data.memberInfo.defaultAccount;
	}
	//全部提现
	const handleSelectAll=()=>{
		withdrawApplyForm.amount=withdrawApplyForm.monetary;
	}
	// 表单校验函数
	const applyWithdraw = (formEl) => {
		if (!formEl) return
		formEl.validate(async (valid) => {
			if (valid) {
				if(withdrawApplyForm.amount<=0){
					ElMessage({
						message: '提现金额不能少于或等于0',
						type: 'error',
						duration:1000
					})
					return
				}
				if(withdrawApplyForm.amount>withdrawApplyForm.monetary){
					ElMessage({
						message: '提现金额需小于可提现金额',
						type: 'error',
						duration:1000
					})
					return
				}
				let params={
					objectType:1,
					amount:withdrawApplyForm.amount,
					accountName:withdrawApplyForm.accountName,
					bank:withdrawApplyForm.bank,
					subbranch:withdrawApplyForm.subbranch,
					defaultAccount:withdrawApplyForm.defaultAccount,
				}
				await withdrawApply(params).then(() => {
					ElMessage({
						message: '提交成功',
						type: 'success',
						duration: 1000
					})
					dialogVisible.value = false;
					emit('update', true)
				})
			} else {
				return false
			}
		})
	}
	
	//打开弹窗时
	const dialogVisible = ref(false);
	const onChangeVisable = () => {
		dialogVisible.value = true;
		getMemberMonetary();
	};
	
	defineExpose({
		onChangeVisable
	});
	
	
	//关闭弹窗重置表单
	const closeDialog=()=>{
		dialogVisible.value = false;
		withdrawApplyFormRef.value.resetFields();
	}
</script>

<style lang="scss">
	.myMoney{
		.el-input-number .el-input__inner{
			text-align: left !important;
		}
	}
</style>
<style lang="scss" scoped>
	.monetary{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom:15px;
		.font-bold{font-weight:bold;color:#FE5BA3;}
	}
	.tips-wrap{
		margin-top:15px;
		color:red;
		font-weight:bold;
		line-height:30px;
	}
</style>