<template>
  <el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="订单详情" draggable width="1300px">
    <!-- 订购详细信息表格 -->
    <div class="title">订购详细信息</div>
    <el-table :data="orderDetails.orderData" border style="width: 100%" v-loading="orderDetails.loading"
      empty-text="没有找到相关信息！" show-summary :summary-method="getSummaries"
      :header-cell-style="{ background: '#f2f2f2', color: '#3a3a3a' }">
      <el-table-column prop="OrderTime" label="订购时间" align="center"></el-table-column>
      <el-table-column prop="Brand,ProductName,Grade" label="品牌|品名|等级" align="center">
        <template #default="scope">
          <span><span style="color:#38adff;">{{ scope.row.Brand }}</span>{{ scope.row.ProductName }}{{ scope.row.Grade
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="MainCategory" label="大类" align="center"></el-table-column>
      <el-table-column prop="SubCategory" label="小类" align="center"></el-table-column>
      <el-table-column prop="Color" label="颜色" align="center"></el-table-column>
      <el-table-column prop="ProductPrice" label="单价" align="center"></el-table-column>
      <el-table-column prop="OrderCount" label="订购数" align="center" width="70"></el-table-column>
      <el-table-column prop="LackCount" label="缺货数" align="center" width="70"></el-table-column>
      <el-table-column prop="EffectiveCount" label="有效数 " align="center" width="70"></el-table-column>
      <el-table-column prop="OrderAmount" label="订购额" align="center" width="70">
        <template #default="scope">
          <span>{{ scope.row.OrderAmount.toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="LacklAmount" label="缺货额" align="center" width="70">
        <template #default="scope">
          <span>{{ scope.row.LacklAmount.toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="EffectiveAmount" label="有效额 " align="center" width="70">
        <template #default="scope">
          <span>{{ scope.row.EffectiveAmount.toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Commission" label="佣金 " align="center" width="70">
        <template #default="scope">
          <span>{{ scope.row.Commission.toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="SaleType" label="类型" align="center" width="70"></el-table-column>
      <el-table-column prop="" label="评价" align="center" width="70">
        <template #default="scope">
          <el-button text @click="toEvaluate(scope.row)" style="padding:0px;color:#38adff"
            v-if="scope.row.EvaluateId != ''">查看评价</el-button>
          <el-button text @click="toEvaluate(scope.row)" style="padding:0px;color:#38adff"
            v-if="scope.row.EvaluateId == '' && (scope.row.OrderStatus == 3 || scope.row.OrderStatus == 8)">去评价</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="" label="产品详情" align="center" width="100">
        <template #default="scope">
          <el-button text @click="readProductDetail(scope.row)" style="padding:0px;color:#38adff">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="title">收货地址：{{ orderDetails.deliAddress }}</div>
    <div class="title">运费详细信息</div>
    <el-table :data="orderDetails.freightData" border style="width: 100%" table-layout="auto"
      v-loading="orderDetails.loading" empty-text="没有找到相关信息！"
      :header-cell-style="{ background: '#f2f2f2', color: '#3a3a3a' }">
      <el-table-column prop="saleDate" label="发货日期" align="center" width="150">
        <template #default="scope">
          <span>{{ moment(scope.row.saleDate).format('YYYY-MM-DD') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="logisticsType" label="发货方式" align="center"></el-table-column>
      <el-table-column prop="flightNum" label="航班号" align="center"></el-table-column>
      <el-table-column prop="logNum" label="件数" align="center" width="65"></el-table-column>
      <el-table-column prop="weight" label="重量" align="center" width="65"></el-table-column>
      <el-table-column label="箱号"
        prop="packNum1,packNum11,packNum12,packNum2,packNum3,packNum4,packNum45,packNum5,packNum6" align="center">
        <template #default="scope">
          <span v-if="scope.row.packNum1 > 0">{{ '10号箱:' + scope.row.packNum1 + ';' }}</span>
          <span v-if="scope.row.packNum11 > 0">{{ '10#1号箱:' + scope.row.packNum11 + ';' }}</span>
          <span v-if="scope.row.packNum12 > 0">{{ '10#2号箱:' + scope.row.packNum12 + ';' }}</span>
          <span v-if="scope.row.packNum2 > 0">{{ '20号箱:' + scope.row.packNum2 + ';' }}</span>
          <span v-if="scope.row.packNum3 > 0">{{ '30号箱:' + scope.row.packNum3 + ';' }}</span>
          <span v-if="scope.row.packNum4 > 0">{{ '40号箱:' + scope.row.packNum4 + ';' }}</span>
          <span v-if="scope.row.packNum45 > 0">{{ '45号箱:' + scope.row.packNum45 + ';' }}</span>
          <span v-if="scope.row.packNum5 > 0">{{ '50号箱:' + scope.row.packNum5 + ';' }}</span>
          <span v-if="scope.row.packNum6 > 0">{{ '60号箱:' + scope.row.packNum6 + ';' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="pickingCosts" label="打包费" align="center"></el-table-column>
      <el-table-column prop="freight" label="运费" align="center" width="65"></el-table-column>
      <el-table-column prop="serviceFee" label="服务费 " align="center" width="65"></el-table-column>
      <el-table-column prop="pickNum" label="桶数" align="center" width="65"></el-table-column>
      <el-table-column prop="pickFee" label="自提费" align="center" width="65"></el-table-column>
      <el-table-column prop="boxPlaceType" label="保暖选择 " align="center">
        <template #default="scope">
          <span v-if="scope.row.boxPlaceType == 0">无</span>
          <span v-if="scope.row.boxPlaceType == 1">单层</span>
          <span v-if="scope.row.boxPlaceType == 2">2-3层</span>
          <span v-if="scope.row.boxPlaceType == 3">4层</span>
        </template>
      </el-table-column>
      <el-table-column prop="warmthFee" label="保暧费" align="center" width="65"></el-table-column>
      <el-table-column prop="storageFee" label="保管费" align="center" width="65"></el-table-column>
      <el-table-column prop="allFee" label="合计 " align="center"></el-table-column>
    </el-table>
    <evaluate-dialog ref="evaluateRef" @update="getOrderDetailList"></evaluate-dialog>
  </el-dialog>
  <!-- 商品详情 -->
  <pro-details-dialog ref="proDetailsRef" />
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted
} from 'vue';
import {
  webOrderDetailList
} from '@/api/user';
import moment from 'moment';
import evaluateDialog from './evaluateDialog.vue';
import proDetailsDialog from '@/components/product-detail/index.vue';

//订单详情数据源
const orderDetails = reactive({
  deliAddress: '',//收货地址
  orderData: [], //表格数据
  loading: true
})
/**
 * 获取账单查询列表
 * @param {无}
 */
const orderId = ref('')
const getOrderDetailList = () => {
  orderDetails.loading = true;
  return webOrderDetailList({
    orderId: orderId.value
  }).then(res => {
    orderDetails.orderData = res.data.records.map(item => {
      return {
        ...item
      }
    })
    orderDetails.loading = false;
    let arr = [];
    arr.splice(0, 1, res.data.saleInvoice);
    orderDetails.freightData = arr;
    orderDetails.deliAddress = res.data.saleInvoice.deliAddress;
  })

}

const dialogVisible = ref(false);
const onChangeVisible = (Id) => {
  orderId.value = Id
  dialogVisible.value = true;
  getOrderDetailList();
};

defineExpose({
  onChangeVisible
});

//自定义合计列，不自定义时会合计单价
const getSummaries = (param) => {
  const { columns, data } = param
  const sums = []
  columns.forEach((column, index) => {
    if (index === 5) {
      sums[index] = '合计'
      return
    }
    if (column.property === 'OrderCount') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
    } else if (column.property === 'LackCount') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
    } else if (column.property === 'EffectiveCount') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
    } else if (column.property === 'OrderAmount') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
      sums[index] = sums[index].toFixed(2); //保留2位小数
    } else if (column.property === 'LacklAmount') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
      sums[index] = sums[index].toFixed(2); //保留2位小数
    } else if (column.property === 'EffectiveAmount') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
      sums[index] = sums[index].toFixed(2); //保留2位小数
    } else if (column.property === 'Commission') {
      const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr) // 将values中的每个值转换为number类型
        if (!isNaN(value)) {
          return prev + curr
        } else {
          return prev
        }
      }, 0)
      sums[index] = sums[index].toFixed(2); //保留2位小数
    } else {
      sums[index] = ''
    }
  })

  return sums
};


// 去评价
const evaluateRef = ref();
const toEvaluate = (event) => {
  const params = {
    SaleType: event.SaleType,
    Id: event.Id,
    Brand: event.Brand,
    ProductName: event.ProductName,
    Grade: event.Grade,
    EvaluateId: event.EvaluateId
  }
  evaluateRef.value.onChangeEvaluateVisible(params);
}

// 查看产品详情
const proDetailsRef = ref(null);
const readProductDetail = (event) => {
  const params = {
    code: event.ProductCode
  }
  proDetailsRef.value.onChangeVisible(params);
}

</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #3A3A3A;
  line-height: 36px;
}

.my-tr th {
  background-color: #f2f2f2 !important;
}
</style>
