<template>
  <div class="header-wrap">
    <div class="header-box w">
      <div class="nav-box">
        <!-- logo -->
        <div class="logo u-m-r-15">
          <!-- <router-link to="/">
						<img class="logoImg" src="@/assets/image/nav-logo.png" />
					</router-link> -->
        </div>
        <!-- 导航 -->
        <ul class="nav u-m-l-15">
          <li>
            <router-link to="/index/home" active-class="selected-link">首页</router-link>
          </li>
          <li>
            <router-link to="/index/orderProduct" active-class="selected-link">订购产品</router-link>
          </li>
          <li>
            <router-link to="/index/flower" active-class="selected-link">我看花</router-link>
          </li>
          <li>
            <router-link to="/index/personalCenter" active-class="selected-link">个人中心</router-link>
          </li>
        </ul>
      </div>
      <!-- 个人信息 -->
      <div class="login">
        <!-- <div class="user-box u-m-t-5"> -->
        <!--   <div class="u-flex-row"> -->
        <!--     <div class="user-info">{{ userInfo.memberDetailInfo.memberName }}[{{ userInfo.memberDetailInfo.memberCode -->
        <!--       }}] -->
        <!--       余额：<text class="balance">{{ userInfo.memberDetailInfo.balance.toFixed(2) }}元</text></div> -->
        <!--     <el-button size="small" type="primary" class="u-m-l-15 u-m-r-15" -->
        <!--       @click="rechargeRef.onChangeVisible()">充值</el-button> -->
        <!--     <div class="user-code" -->
        <!--       v-if="userInfo.memberDetailInfo.memberType == '普通会员' && getApplyTime < 60 && getExpiration > 0"> -->
        <!--       距试用期结束还有<span class="expiration">{{ getExpiration }}</span>天</div> -->
        <!--     <div class="user-code" -->
        <!--       v-if="userInfo.memberDetailInfo.memberType == '普通会员' && getApplyTime >= 60 && getExpiration <= 30 && getExpiration > 0"> -->
        <!--       距离会员到期还有<span class="expiration">{{ getExpiration }}</span>天</div> -->
        <!--     <div class="user-code" -->
        <!--       v-if="userInfo.memberDetailInfo.memberType == '普通会员' && getApplyTime >= 60 && getExpiration <= 0"><span -->
        <!--         class="expiration">年费已到期</span></div> -->
        <!--   </div> -->
        <!--   <div style="cursor: pointer;" class="u-flex-row-content u-m-t-5" -->
        <!--     @click="address.onAddressVisible(userInfo.memberDetailInfo.addressId)"> -->
        <!--     <div class="address">收货地址：{{ userInfo.memberDetailInfo.shippingAddress }}</div> -->
        <!--     <el-icon :size="20"> -->
        <!--       <ArrowRight /> -->
        <!--     </el-icon> -->
        <!--   </div> -->
        <!-- </div> -->
        <div class="font-18" v-if="userInfo.memberDetailInfo.memberType">
          <template v-if="userInfo.memberDetailInfo.memberType == '普通会员' && getApplyTime < 60 && getExpiration > 0">
            距离试用期结束还有<span style="color: #fe5ba3">{{ getExpiration }}</span
            >天
          </template>
          <template
            v-if="
              userInfo.memberDetailInfo.memberType == '普通会员' &&
              getApplyTime >= 60 &&
              getExpiration <= 30 &&
              getExpiration > 0
            "
          >
            距离会员到期还有<span style="color: #fe5ba3">{{ getExpiration }}</span
            >天
          </template>
          <template
            v-if="userInfo.memberDetailInfo.memberType == '普通会员' && getApplyTime >= 60 && getExpiration <= 0"
          >
            年费已到期
          </template>
        </div>
        <div class="font-18">
          &nbsp;余额：{{ userInfo.memberDetailInfo.balance.toFixed(2) }}
          <el-button size="small" type="primary" class="u-m-l-15 u-m-r-15" @click="rechargeRef.onChangeVisible()"
            >充值</el-button
          >
        </div>
        <div class="font-18" v-if="userInfo.memberInfo.memberName">
          欢迎您,{{ userInfo.memberInfo.memberName + '(' + userInfo.memberInfo.memberCode + ')' }} |
          <el-button @click="logout" text>退出</el-button>
        </div>
        <div class="font-18" v-else>
          <router-link to="/login">登录</router-link> | <router-link to="/register">注册</router-link>
        </div>
      </div>
    </div>
    <rechargeModel ref="rechargeRef"></rechargeModel>
  </div>
</template>

<script setup>
import { userStore } from '@/store/user';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import rechargeModel from './recharge-modal/index.vue';
const userInfo = userStore();
userInfo.getMemberInfo();

const rechargeRef = ref();

const router = useRouter();
const logout = () => {
  userInfo.memberInfo = '';
  router.push({
    path: '/login'
  });
};

//计算到期时间
const getExpiration = computed(() => {
  let recentTime = new Date(userInfo.memberDetailInfo.recentTime);
  let currentTime = new Date();
  let expirationTime = recentTime - currentTime;
  let expirationDays = Math.floor(expirationTime / (24 * 3600 * 1000));
  return expirationDays;
});

//计算当前时间-注册时间相隔天数
const getApplyTime = computed(() => {
  let applyTime = new Date(userInfo.memberDetailInfo.applyTime);
  let currentTime = new Date();
  let diffTime = currentTime - applyTime;
  let diffDays = Math.floor(diffTime / (24 * 3600 * 1000));
  return diffDays;
});
</script>

<style lang="scss">
$height: 70px;

.header-wrap {
  background-color: #ffffff;
  height: $height;
  line-height: $height;
  border-bottom: 1px solid #e6e6e6;

  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-box {
      display: flex;

      // logo
      .logo {
        .logoImg {
          width: 240px;
          height: 60px;
        }
      }

      // 导航
      .nav {
        display: flex;

        li a {
          display: inline-block;
          height: 50px;
          line-height: 50px;
          padding: 0px 25px;
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }

        .selected-link {
          color: #fe5ba3;
          border-bottom: 4px solid #fe5ba3;
        }
      }
    }

    .login {
      display: flex;
      flex-direction: row;

      .el-button > span {
        font-size: 18px;
      }

      .el-button.is-text {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }
}
</style>
