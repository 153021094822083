<template>
	<div class="title">我的资料</div>
	<el-form :model="infoForm" ref="ruleFormRef" :rules="rules" label-width="120px" class="info-form" size="large">
		<el-form-item label="会员号：" prop="code">
			<el-input type="text" v-model="infoForm.code" disabled></el-input>
		</el-form-item>
		<el-form-item label="花店/公司名称：" prop="name">
			<el-input type="text" v-model="infoForm.name" disabled></el-input>
		</el-form-item>
		<el-form-item label="联系人：" prop="linkMan">
			<el-input type="text" v-model="infoForm.linkMan"></el-input>
		</el-form-item>
		<el-form-item label="手机：" prop="phone">
			<el-input type="text" v-model="infoForm.phone"></el-input>
		</el-form-item>
		<el-form-item label="微信：">
			<el-input type="text" v-model="infoForm.weiXin"></el-input>
		</el-form-item>
		<el-form-item label="电子邮箱：">
			<el-input type="text" v-model="infoForm.email"></el-input>
		</el-form-item>
		<el-form-item label="地址：" prop="address">
			<el-select v-model="infoForm.province" filterable placeholder="省" class="u-m-r-15"
				@change="selectedProvince">
				<el-option v-for="item in provinceOptions" :key="item.value" :label="item.Province"
					:value="item.Province" />
			</el-select>
			<el-select v-model="infoForm.city" filterable placeholder="市" :disabled="!infoForm.province"
				class="u-m-r-15" @change="selectedCity" @clear="clearSelectedCity">
				<el-option v-for="item in cityOptions" :key="item.value" :label="item.City" :value="item.City" />
			</el-select>
			<el-select v-model="infoForm.area" filterable placeholder="区"
				:disabled="!infoForm.province || !infoForm.city" class="u-m-r-15" @change="selectedArea"
				@clear="clearSelectedArea">
				<el-option v-for="item in areaOptions" :key="item.value" :label="item.Area" :value="item.Area" />
			</el-select>
			<el-select v-model="infoForm.street" filterable placeholder="镇/街"
				:disabled="!infoForm.province || !infoForm.city || !infoForm.area" @clear="clearSelectedStreet">
				<el-option v-for="item in streetOptions" :key="item.value" :label="item.Street" :value="item.Street" />
			</el-select>
		</el-form-item>
		<el-form-item label="详细地址：" prop="addr">
			<el-input type="text" v-model="infoForm.addr"></el-input>
		</el-form-item>
		<el-form-item label="收货地址：">
			<el-input type="text" v-model="infoForm.deliAddress"></el-input>
		</el-form-item>
		<el-form-item label="经营范围：">
			<el-checkbox-group v-model="infoForm.scopeList">
				<el-checkbox v-for="item in infoForm.scopeCheckBoxList" :key="item.ParaName" :label="item.ParaName" />
			</el-checkbox-group>
		</el-form-item>
		<el-form-item label="经营性质：">
			<el-checkbox-group v-model="infoForm.natureList">
				<el-checkbox v-for="item in infoForm.natureCheckBoxList" :key="item.ParaName" :label="item.ParaName" />
			</el-checkbox-group>
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="updateInfo(ruleFormRef)">保存</el-button>
		</el-form-item>
	</el-form>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import {
		getMemberInfo,
		getProvinceList,
		getDistrictCityList,
		getDistrictAreaList,
		getDistrictStreetList,
		updateMemberInfo,
		parametersScopeList,
		parametersNatureList
	} from '@/api/user';

	import {
		getMyProductManager
	} from '@/api/productSale'

	import {
		userStore
	} from '@/store/user';
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus';
	const ruleFormRef = ref();
	const infoForm = reactive({
		id: '',
		code: '',
		name: '',
		linkMan: '',
		phone: '',
		weiXin: '',
		email: '',
		province: '',
		city: '',
		area: '',
		street: '',
		addr: '',
		deliAddress: '',
		scopeList: [],
		natureList: [],
		scopeCheckBoxList: [], //经营范围
		natureCheckBoxList: [], //经营性质
	});
	
	/**
	 * 取客户经理信息
	 * @param {无}
	 */
	const customerManager = reactive({
		tel: '',
		managerName: ''
	})

	const getMyAccountManager = async () => {
		const {
			data: {
				productManagerInfo
			}
		} = await getMyProductManager()
		customerManager.tel = productManagerInfo.tel;
		customerManager.managerName = productManagerInfo.managerName;
	}

	//表单验证
	const validateAddress = (rule, value, callback) => {
		if (infoForm.province == '' || infoForm.city == '' || infoForm.area == '' || infoForm.street == '') {
			callback(new Error("请选择地址"));
		} else {
			callback();
		}
	};
	const rules = reactive({
		code: [{
			required: true,
			message: '会员号不能为空',
			trigger: 'blur'
		}],
		name: [{
			required: true,
			message: '花店/公司名称不能为空',
			trigger: 'blur'
		}],
		email: [{
			pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/,
			message: '请输入合法的邮箱',
			trigger: 'blur'
		}],
		linkMan: [{
			required: true,
			message: '联系人不能为空',
			trigger: 'blur'
		}],
		phone: [{
			required: true,
			message: '手机号码不能为空',
			trigger: 'blur'
		}, {
			pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
			message: '手机号码格式不正确',
			trigger: 'blur'
		}],
		address: [{
			required: true,
			validator: validateAddress,
			message: '地址不能为空',
			trigger: 'blur'
		}],
	});

	onMounted(async () => {
		await initData()
	});

	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getScopeCheckBoxList()
		await getNatureCheckBoxList()
		await memberInfo()
		await getProvince()
		await getCity()
		await getArea()
		await getStreet()
	}
	//备份原先的会员信息的地址信息
	const prevInfoForm=reactive({
		province: '',
		city: '',
		area: '',
		street: '',
		addr: '',
		deliAddress: ''
	});
	
	//获取会员信息
	const memberInfo = () => {
		let id = userStore().memberInfo.memberId;
		return getMemberInfo().then(res => {
			let memberInfo = res.data.memberInfo;
			infoForm.id = memberInfo.id;
			infoForm.code = memberInfo.code;
			infoForm.name = memberInfo.name;
			infoForm.linkMan = memberInfo.linkMan;
			infoForm.phone = memberInfo.phone;
			infoForm.weiXin = memberInfo.weiXin;
			infoForm.email = memberInfo.email;
			infoForm.province = memberInfo.province;
			infoForm.city = memberInfo.city;
			infoForm.area = memberInfo.area;
			infoForm.street = memberInfo.street;
			infoForm.addr = memberInfo.addr;
			infoForm.deliAddress = memberInfo.deliAddress;
			infoForm.scopeList = memberInfo.scopeList ? memberInfo.scopeList.split(';') : [];
			infoForm.natureList = memberInfo.natureList ? memberInfo.natureList.split(';') : [];
			
			//备份原先的会员信息的地址信息
			prevInfoForm.province = memberInfo.province;
			prevInfoForm.city = memberInfo.city;
			prevInfoForm.area = memberInfo.area;
			prevInfoForm.street = memberInfo.street;
			prevInfoForm.addr = memberInfo.addr;
			prevInfoForm.deliAddress = memberInfo.deliAddress;
		})
	}

	// 行政区
	const provinceOptions = ref([]);
	const cityOptions = ref([]);
	const areaOptions = ref([]);
	const streetOptions = ref([]);
	// 获取省列表
	const getProvince = () => {
		return getProvinceList({}).then(res => {
			provinceOptions.value = res.data.records;
		})
	}
	//选择省份
	const selectedProvince = (province) => {
		clearSelectedCity()
		infoForm.province = province;
		getCity(province)
	}
	// 根据省份获取城市列表
	const getCity = () => {
		return getDistrictCityList({
			province: infoForm.province
		}).then(res => {
			cityOptions.value = res.data.records;
		})

	}
	//选择城市
	const selectedCity = (city) => {
		clearSelectedArea()
		infoForm.city = city;
		getArea(city)
	}
	//根据城市获取区列表
	const getArea = () => {
		let param = {
			province: infoForm.province,
			city: infoForm.city
		}
		return getDistrictAreaList(param).then(res => {
			areaOptions.value = res.data.records;
		})
	}
	//选择区
	const selectedArea = (area) => {
		clearSelectedStreet()
		infoForm.area = area;
		getStreet(area)
	}
	//根据区县获取街道列表
	const getStreet = () => {
		let param = {
			province: infoForm.province,
			city: infoForm.city,
			area: infoForm.area
		}
		return getDistrictStreetList(param).then(res => {
			streetOptions.value = res.data.records;
		})
	}

	// 切换省时，清空原先的市和区和街
	const clearSelectedCity = () => {
		infoForm.city = ''
		infoForm.area = ''
		infoForm.street = ''
	}

	// 切换市时，清空原先的区和街
	const clearSelectedArea = () => {
		infoForm.area = ''
		infoForm.street = ''
	}

	// 切换区时，清空原先的街
	const clearSelectedStreet = () => {
		infoForm.street = ''
	}
	//获取经营范围
	const getScopeCheckBoxList = () => {
		return parametersScopeList({}).then(res => {
			infoForm.scopeCheckBoxList = res.data.records
		})
	}
	//获取经营性质
	const getNatureCheckBoxList = () => {
		return parametersNatureList({}).then(res => {
			infoForm.natureCheckBoxList = res.data.records
		})
	}
	//保存信息
	const updateInfo = (form) => {
		form.validate((valid, fields) => {
			if (valid) {
				let scopeList = infoForm.scopeList;
				let scope = "";
				if (scopeList.length != 0) {
					scopeList.map(v => {
						scope += v + ';'
					})
					scope = scope.slice(0, scope.length - 1)
				}

				let natureList = infoForm.natureList;
				let nature = "";
				if (natureList.length != 0) {
					natureList.map(v => {
						nature += v + ';'
					})
					nature = nature.slice(0, nature.length - 1)
				}

				let res = {
					id: infoForm.id,
					code: infoForm.code,
					name: infoForm.name,
					linkMan: infoForm.linkMan,
					phone: infoForm.phone,
					weiXin: infoForm.weiXin,
					email: infoForm.email,
					province: infoForm.province,
					city: infoForm.city,
					area: infoForm.area,
					street: infoForm.street,
					addr: infoForm.addr,
					deliAddress: infoForm.deliAddress,
					scopeList: scope,
					natureList: nature
				}
				getMyAccountManager();
				updateMemberInfo(res).then(() => {
					if (prevInfoForm.province!==infoForm.province||prevInfoForm.city!==infoForm.city||prevInfoForm.area!==infoForm.area||prevInfoForm.street!==infoForm.street||prevInfoForm.addr!==infoForm.addr||prevInfoForm.deliAddress!==infoForm.deliAddress) {
						ElMessageBox({
							showClose: false,
							title: '温馨提示',
							message: '已提交修改申请，客户经理审核后即可生效，联系：' + customerManager.managerName +' ' + customerManager.tel,
						})
					}else {
						ElMessage({
							message: '修改成功',
							type: 'success',
							duration: 1000
						})
					}
				})
			}
		})
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #3A3A3A;
		line-height: 55px;
	}

	.info-form {
		font-size: 17px;

		.el-form-item__label {
			font-size: 17px;
			font-weight: 400;
			color: #3D3D3D;
		}

		.el-input {
			width: 845px;
		}

		.el-select {
			width: 200px;
		}

		.el-button {
			margin-top: 30px;
			width: 200px;
			height: 44px;
			line-height: 44px;
			border-radius: 22px;
			font-size: 20px;
			font-weight: 400;
			color: #FFFFFF;
		}
	}
</style>