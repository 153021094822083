<template>
	<div class="title">预定查询</div>
	<!-- 搜索栏 -->
	<el-form ref="formRef" :model="searchForm" inline>
		<el-form-item label="销售时间：" prop="SaleDate">
			<el-date-picker v-model="searchForm.SaleDate" type="daterange" start-placeholder="开始时间"
				end-placeholder="结束时间" range-separator="至" :default-value="[new Date()]" clearable
				@change="change(searchForm.SaleDate)" />
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="getBookOrderList()">查询</el-button>
		</el-form-item>
	</el-form>
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="SaleDate" label="销售时间" align="center">
			<template #default="scope">
				<span>{{ moment(scope.row.SaleDate).format('YYYY-MM-DD') }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="EffectiveTotalCount" label="预定数量" align="center"></el-table-column>
		<el-table-column prop="EffectiveTotalAmount" label="预定金额" align="center">
			<template #default="scope">
				<span>{{ scope.row.EffectiveTotalAmount.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="operation" label="详情" align="center">
			<template #default="scope">
				<el-button text @click="toDetails(scope.row.Id)" style="color:#38adff">查看</el-button>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
	<order-book-details-dialog ref="orderBookDetailsRef"></order-book-details-dialog>
</template>

<script setup>
	import {
		ref,reactive,onMounted 
	} from 'vue';
	import {
		webMainBookOrderList
	} from '@/api/user';
	import moment from 'moment';
	import orderBookDetailsDialog from './orderBookDetailsDialog.vue';
	const searchForm = reactive({
		SaleDate: ''
	});
	
	// 订单详情
	const orderBookDetailsRef = ref();
	const toDetails = (Id) =>{
		orderBookDetailsRef.value.onChangeVisible(Id);
	}
	
	//数据源
	const state = reactive({
		startSaleDate :'',
		endSaleDate:'',
		params: {
			limit: {
				enable: true,
				conditions: {
					pageLength: 15,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})
	onMounted(async () => {
		await initData();
	})
	
	const change = (val) => {
		if (val) {
			const v = JSON.parse(JSON.stringify(val))
			state.params.startSaleDate = moment(v[0]).format('YYYY-MM-DD')
			state.params.endSaleDate = moment(v[1]).format('YYYY-MM-DD')
			
		} else {
			state.params.startSaleDate = ''
			state.params.endSaleDate = ''
			getBookOrderList()
		}
		
	}
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getBookOrderList()
	}
	/**
	 * 获取账单查询列表
	 * @param {无}
	 */
	const getBookOrderList = () => {
		state.loading = true;
		return webMainBookOrderList(state.params).then(res => {
			const {data: {filterAmount,records}} = res
			state.data = records.map(item => {
				return {
					...item
				}
			})
			state.total = filterAmount
			state.loading = false
		})
	}
	
	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getBookOrderList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getBookOrderList()
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #3A3A3A;
		line-height: 55px;
	}
</style>
