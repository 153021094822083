<template>
  <!-- 品牌弹窗 -->
  <el-dialog
    :model-value="brandDialogVisible"
    @close="$emit('update:brandDialogVisible', false)"
    @open="handleOpen"
    title="请选择品牌"
    center
  >
    <div class="bandList">
      <el-checkbox-group v-model="state.brand">
        <el-checkbox v-for="(item, index) in state.brand_list" :key="index" :label="item.Brand">
          <template #default>
            <span
              >{{ item.Brand }}<span style="color: #1c9cf6">{{ item.nmBrand }}</span></span
            >
          </template>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleBrandSure()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { findPresaleBrand, productBrandList } from '@/api/productSale';
import { withDefaultsParams } from '@/common';
import { SALE_TYPE } from '@/common/const';
import { reactive, watch } from 'vue';

const props = defineProps({
  brandDialogVisible: {
    type: Boolean,
    default: false
  },
  sale_type: {
    type: [String, Number],
    default: SALE_TYPE.TODAY
  },
  main_category: {
    type: String,
    default: ''
  },
  sub_category: {
    type: String,
    default: ''
  },
  product_name: {
    type: String,
    default: ''
  },
  grade: {
    type: String,
    default: ''
  },
  sale_date: {
    type: String,
    default: ''
  },
  tag_id: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  brand: {
    type: String,
    default: ''
  }
});

const state = reactive({
  brand: [],
  brand_list: []
});

const emit = defineEmits(['update:brandDialogVisible', 'update_brand', 'update_brand_code']);

watch(
  () => props.brand,
  (newValue, oldValue) => {
    state.brand = props.brand == '' ? [] : props.brand.split(',');
    updatePresaleBrandCode();
  }
);

/**
 * 获取品牌列表
 */
const getBrandList = withDefaultsParams({
  saleType: props.sale_type,
  mainCategory: props.main_category,
  subCategory: props.sub_category,
  productName: props.product_name,
  grade: props.grade,
  saleDate: props.sale_date,
  tagId: props.tag_id
})(async params => {
  params.saleType == 6 ? (params.saleDate = props.sale_date) : delete params.saleDate;
  const {
    data: { records }
  } = await productBrandList(params);
  state.brand_list = records;
});

const getPresaleBrandList = withDefaultsParams({
  sale_date: props.sale_date,
  main_category: props.main_category,
  sub_category: props.sub_category,
  keywords: props.keywords,
  color: props.color,
  grade: props.grade
})(async params => {
  const { data } = await findPresaleBrand(params);
  state.brand_list = data.map(item => {
    return {
      Code: item.Code,
      nmBrand: item.count,
      Brand: item.name
    };
  });
});

function updatePresaleBrandCode(params) {
  let list = state.brand_list
    .filter(item => state.brand.findIndex(brand => brand == item.Brand) !== -1)
    .map(item => item.Code);
  emit('update_brand_code', list.join(','));
}

/**
 * 确定选择品牌
 */
function handleBrandSure(event) {
  console.log('brandsssdfsdfsafasf', state.brand);
  emit('update:brandDialogVisible', false);
  emit('update_brand', state.brand.join(','));
  updatePresaleBrandCode();
}

function handleOpen() {
  if (props.sale_type == SALE_TYPE.AFTERNOON) {
    console.log(props);
    getPresaleBrandList({
      sale_date: props.sale_date,
      main_category: props.main_category,
      sub_category: props.sub_category,
      keywords: props.keywords,
      color: props.color,
      grade: props.grade
    });
  } else {
    getBrandList({
      saleType: props.sale_type,
      mainCategory: props.main_category,
      subCategory: props.sub_category,
      productName: props.product_name,
      grade: props.grade,
      saleDate: props.sale_date,
      tagId: props.tag_id
    });
  }
}
</script>

<style lang="scss"></style>
