<template>
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="InvoiceNum" label="发票编号" align="center" ></el-table-column>
		<el-table-column prop="InvoiceType" label="发票类型" align="center">
			<template #default="scope">
				<span v-if="scope.row.InvoiceType==1">普票</span>
				<span v-if="scope.row.InvoiceType==2">增值税专用票</span>
			</template>
		</el-table-column>
		<el-table-column prop="IssueType" label="抬头类型" align="center">
			<template #default="scope">
				<span v-if="scope.row.IssueType==1">个人</span>
				<span v-if="scope.row.IssueType==2">单位</span>
			</template>
		</el-table-column>
		<el-table-column prop="TaxNum" label="税号" align="center"></el-table-column>
		<el-table-column prop="ProductAmount" label="订单有效金额" align="center"></el-table-column>
		<el-table-column prop="ServiceAmount" label="服务费" align="center"></el-table-column>
		<el-table-column prop="InvoiceAmount" label="开票额" align="center"></el-table-column>
		<el-table-column prop="ApplyTime" label="申请时间" align="center"></el-table-column>
		<el-table-column prop="OrderState" label="状态" align="center">
			<template #default="scope">
				<span v-if="scope.row.OrderState==1">用户申请</span>
				<span v-if="scope.row.OrderState==2">已取消</span>
				<span v-if="scope.row.OrderState==3">开票申请</span>
				<span v-if="scope.row.OrderState==4">开票成功</span>
				<span v-if="scope.row.OrderState==5">开票失败</span>
				<span v-if="scope.row.OrderState==6">已邮寄</span>
				<span v-if="scope.row.OrderState==7">完成</span>
			</template>
		</el-table-column>
		<el-table-column prop="operation" label="操作" align="center">
			<template #default="scope">
				<el-button text @click="toDetails(scope.row.Id)" style="padding:0px;color:#38adff">查看明细</el-button>
				<el-button text @click="getInvoicePdf(scope.row.Id)" style="padding:0px;color:#38adff;margin-left:5px !important;">查看PDF</el-button>
				<el-button text @click="getInvoiceImg(scope.row.Id)" style="padding:0px;color:#38adff;margin-left:5px !important;">查看图片</el-button>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
	<invoice-details ref="invoiceDetailsRef"></invoice-details>
</template>

<script setup>
	import {reactive,ref,onMounted} from 'vue';
	import {webInvoiceList,webInvoiceResult} from '@/api/user';
	import moment from 'moment'
	import invoiceDetails from './invoiceDetails.vue'
	onMounted(async () => {
		await initData();
	})
	
	//数据源
	const state = reactive({
		params: {
			limit: {
				enable: true,
				conditions: {
					pageLength: 10,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})
	
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getInvoiceList()
		//await getInvoice()
	}
	/**
	 * 获取抬头列表
	 * @param {无}
	 */
	const getInvoiceList = () => {
		state.loading = true
		return webInvoiceList(state.params).then(res => {
			const {data: {filterAmount,records}} = res
			state.data = records.map(item => {
				return {...item}
			})
			state.total = filterAmount
			state.loading = false
		})
	}
	
	// 发票明细
	const invoiceDetailsRef = ref(false);
	const toDetails = (Id) =>{
		invoiceDetailsRef.value.onChangeVisible(Id);
	}
	
	//查看PDF
	const invoiceId=ref('');
	const pictureUrl=ref('');
	const pdfUrl=ref('');
	const getInvoicePdf=async(Id)=>{
		invoiceId.value=Id;
		const {data} = await webInvoiceResult({id:invoiceId.value})
		pdfUrl.value=data.pdfUrl;
		window.open(pdfUrl.value, '_blank')
	}
	const getInvoiceImg=async(Id)=>{
		invoiceId.value=Id;
		const {data} = await webInvoiceResult({id:invoiceId.value})
		pictureUrl.value=data.pictureUrl;
		window.open('https://'+pictureUrl.value, '_blank')
	}
	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getInvoiceList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getInvoiceList()
	};
	
	
</script>

<style lang="scss" scoped>
	.tips{
		font-size:12px;
		color:red;
		text-align: center;
		margin-top: 30px;
	}
</style>