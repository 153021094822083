<template>
	<div class="title">物流信息</div>
	<!-- 搜索栏 -->
	<el-form ref="formRef" :model="searchForm" inline>
		<el-form-item label="物流方式：" prop="DeliveryWay">
			<el-input v-model="searchForm.DeliveryWay" placeholder="请输入物流方式" style="width:120px;" clearable @change="changeInputVal"></el-input>
		</el-form-item>
		<el-form-item label="订单编号：" prop="OrderNo">
			<el-input v-model="searchForm.OrderNo" placeholder="请输入订单编号" style="width:120px;" clearable @change="changeInputVal"></el-input>
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="getLogisticsList">查询</el-button>
		</el-form-item>
	</el-form>
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="DeliveryWay" label="物流方式" align="center"></el-table-column>
		<el-table-column prop="DeliveryNum" label="物流单号" align="center"></el-table-column>
		<el-table-column prop="DeliveryStatus" label="物流状态" align="center"></el-table-column>
		<el-table-column prop="OrderNo" label="订单编号" align="center"></el-table-column>
		<el-table-column prop="Phone" label="联系电话" align="center"></el-table-column>
		<el-table-column prop="DetailAddr" label="收货地址" align="center"></el-table-column>
		<el-table-column prop="operation" label="详情" align="center">
			<template #default="scope">
				<el-button text @click="toDetails(scope.row)" style="color:#38adff" v-if="scope.row.DeliveryNum!==''">详情</el-button>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
	<logistics-details-dialog ref="logisticsDetailsRef"></logistics-details-dialog>
</template>

<script setup>
	import {reactive,ref,onMounted} from 'vue';
	import {webOrderLogisticsList} from '@/api/user';
	import logisticsDetailsDialog from './logisticsDialog.vue';
	onMounted(async () => {
		await initData();
	})

	const searchForm = reactive({
		DeliveryWay: '',
		OrderNo: ''
	});
	//数据源
	const state = reactive({
		params: {
			filters: {
				enable: true,
				join:'and',
				conditions: []
			},
			limit: {
				enable: true,
				conditions: {
					pageLength: 15,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})
	
	const changeInputVal=()=>{
		getLogisticsList()
	}
	
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getLogisticsList()
	}
	/**
	 * 获取物流信息列表
	 * @param {无}
	 */
	const getLogisticsList = () => {
		state.params.filters.conditions = [];
		state.loading = true
		state.params.filters.conditions.push({
			name:'DeliveryWay',
			type:'like',
			value: searchForm.DeliveryWay
		},
		{
			name:'OrderNo',
			type:'like',
			value: searchForm.OrderNo
		})
		return webOrderLogisticsList(state.params).then(res => {
			const {data: {filterAmount,records}} = res
			state.data = records.map(item => {
				return {...item}
			})
			state.total = filterAmount
			state.loading = false
		})
	}

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getLogisticsList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getLogisticsList()
	};
	
	// 物流详情
	const logisticsDetailsRef = ref();
	const toDetails = (event) =>{
		const params = {
			DeliveryWay:event.DeliveryWay,
			DeliveryNum:event.DeliveryNum,
			Phone: event.Phone
		}
		logisticsDetailsRef.value.onChangeVisible(params);
	}
</script>

<style lang="scss" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #3A3A3A;
		line-height: 55px;
	}
</style>