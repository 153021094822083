<template>
	<el-button type="primary" @click="addAddress">新增收货地址</el-button>
	<el-table :data="addressTable" border empty-text="还没有收货地址！" v-loading="loading" style="width: 100%"
		table-layout="auto" class="u-m-t-15" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="LinkMan" label="收货人" align="center"></el-table-column>
		<el-table-column prop="Phone" label="手机" align="center"></el-table-column>
		<el-table-column prop="Province,City,Area,Street" label="所在地区" align="center">
			<template #default="scope">
				<span>{{scope.row.Province+scope.row.City+scope.row.Area+scope.row.Street}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="DetailAddr" label="详细地址" align="center"></el-table-column>
		<el-table-column prop="DeliveryWay" label="物流方式" align="center"></el-table-column>
		<el-table-column prop="IsDefault" label="是否为默认" align="center">
			<template #default="scope">
				<span v-if="scope.row.IsDefault==1" class="u-color-primary">是</span>
				<span v-if="scope.row.IsDefault==0">否</span>
			</template>
		</el-table-column>
		<el-table-column prop="AddrState" label="是否审核" align="center">
			<template #default="scope">
				<span v-if="scope.row.AddrState==1" class="u-color-primary">已审核</span>
				<span v-if="scope.row.AddrState==0">未审核</span>
				<span v-if="scope.row.AddrState==2" class="u-color-red">审核不通过</span>
			</template>
		</el-table-column>
		<el-table-column prop="operation" label="操作" align="center">
			<template #default="scope">
				<el-button text  @click="editAddress(scope.row)">修改</el-button>|
				<el-button text type="danger" @click="deleteAddress(scope.row)">删除</el-button>
			</template>
		</el-table-column>
	</el-table>

	<el-dialog v-model="addressVisible" title="新增收货地址" width="550" draggable @close="closeDialog">
		<el-form :model="addressForm" ref="ruleFormRef" :rules="rules" label-width="80px" class="info-form" size="large">
			<el-form-item label="收货人：" prop="linkMan">
				<el-input type="text" v-model="addressForm.linkMan"></el-input>
			</el-form-item>
			<el-form-item label="手机：" prop="phone">
				<el-input type="text" v-model="addressForm.phone"></el-input>
			</el-form-item>
			<el-form-item label="所在地区：" prop="address">
				<el-select v-model="addressForm.province" filterable placeholder="省" class="u-m-r-15"
					@change="selectedProvince">
					<el-option v-for="item in provinceOptions" :key="item.value" :label="item.Province"
						:value="item.Province" />
				</el-select>
				<el-select v-model="addressForm.city" filterable placeholder="市" :disabled="!addressForm.province"
					class="u-m-r-15" @change="selectedCity" @clear="clearSelectedCity">
					<el-option v-for="item in cityOptions" :key="item.value" :label="item.City" :value="item.City" />
				</el-select>
				<el-select v-model="addressForm.area" filterable placeholder="区"
					:disabled="!addressForm.province || !addressForm.city" class="u-m-r-15" @change="selectedArea" @clear="clearSelectedArea">
					<el-option v-for="item in areaOptions" :key="item.value" :label="item.Area" :value="item.Area" />
				</el-select>
				<el-select v-model="addressForm.street" filterable placeholder="镇/街"
					:disabled="!addressForm.province || !addressForm.city || !addressForm.area" @clear="clearSelectedStreet">
					<el-option v-for="item in streetOptions" :key="item.value" :label="item.Street" :value="item.Street" />
				</el-select>
			</el-form-item>
			<el-form-item label="详细地址：" prop="detailAddr">
				<el-input type="text" v-model="addressForm.detailAddr"></el-input>
			</el-form-item>
			<el-form-item label="是否默认：" prop="isDefault">
				<el-radio-group v-model="addressForm.isDefault" class="ml-4">
					<el-radio :label="1" size="large">是</el-radio>
					<el-radio :label="0" size="large">否</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="">
				<el-button type="primary" @click="submitAddress(ruleFormRef)">保存</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>

</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import {
		getProvinceList,
		getDistrictCityList,
		getDistrictAreaList,
		getDistrictStreetList,
		memberAddressSave,
		memberAddressList,
		memberAddressInfo,
		memberAddressDelete
	} from '@/api/user';
	
	import {
		getMyProductManager
	} from '@/api/productSale'
	import { ElMessage, ElMessageBox } from 'element-plus'

	onMounted(async () => {
		await initData()
		await getProvince()
	});

	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getAddressList()
	}
	const loading=ref(false)
	const addressTable=ref([]);
	//弹窗
	const addressVisible = ref(false);
	
	const ruleFormRef = ref();
	const addressForm = reactive({
		id:'',
		mode:'add',
		memberId:'',
		linkMan: '',
		phone: '',
		province: '',
		city: '',
		area: '',
		street: '',
		detailAddr: '',
		isDefault:0
	})
	//验证表单
	const validateAddress = (rule, value, callback) => {
		if (addressForm.province == '' || addressForm.city == '' || addressForm.area == '' || addressForm.street == '') {
			callback(new Error("请选择地址"));
		} else {
			callback();
		}
	};
	const rules = reactive({
		linkMan: [{
			required: true,
			message: '收货人不能为空',
			trigger: 'blur'
		}],
		phone: [{
			required: true,
			message: '手机号码不能为空',
			trigger: 'blur'
		}, {
			pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
			message: '手机号码格式不正确',
			trigger: 'blur'
		}],
		address: [{
			required: true,
			validator: validateAddress,
			message: '请选择完整地址',
			trigger: 'blur'
		}],
		detailAddr: [{
			required: true,
			message: '详细地址不能为空',
			trigger: 'blur'
		}]
	});
	
	// 行政区
	const provinceOptions = ref([]);
	const cityOptions = ref([]);
	const areaOptions = ref([]);
	const streetOptions = ref([]);
	// 获取省列表
	const getProvince = () => {
		return getProvinceList({}).then(res => {
			provinceOptions.value = res.data.records;
		})
	}
	//选择省份
	const selectedProvince = (province) => {
		clearSelectedCity()
		addressForm.province = province;
		getCity(province)
	}
	// 根据省份获取城市列表
	const getCity = () => {
		return getDistrictCityList({
			province: addressForm.province
		}).then(res => {
			cityOptions.value = res.data.records;
		})
		
	}
	//选择城市
	const selectedCity = (city) => {
		clearSelectedArea()
		addressForm.city = city;
		getArea(city)
	}
	//根据城市获取区列表
	const getArea = () => {
		let param = {
			province: addressForm.province,
			city: addressForm.city
		}
		return getDistrictAreaList(param).then(res => {
			areaOptions.value = res.data.records;
		})
	}
	//选择区
	const selectedArea = (area) => {
		clearSelectedStreet()
		addressForm.area = area;
		getStreet(area)
	}
	//根据区县获取街道列表
	const getStreet = () => {
		let param = {
			province: addressForm.province,
			city: addressForm.city,
			area: addressForm.area
		}
		return getDistrictStreetList(param).then(res => {
			streetOptions.value = res.data.records;
		})
	}
	
	// 切换省时，清空原先的市和区和街
	const clearSelectedCity=()=>{
		addressForm.city=''
		addressForm.area=''
		addressForm.street=''
	}
	
	// 切换市时，清空原先的区和街
	const clearSelectedArea=()=>{
		addressForm.area=''
		addressForm.street=''
	}
	
	// 切换区时，清空原先的街
	const clearSelectedStreet=()=>{
		addressForm.street=''
	}
	
	/**
	 * 获取销地址列表
	 * @param {无}
	 */
	const getAddressList = () => {
		loading.value=true
		return memberAddressList({}).then(res => {
			addressTable.value=res.data.records
			loading.value=false
		})
	}
	
	// 删除收货地址
	const deleteAddress = (data) => {
		// console.log('id',id)
			ElMessageBox.alert("确定删除该地址吗？", "提示", {
				type: 'warning',
				cancelButtonText: '取消',
				confirmButtonText: '确定'
			}).then(() => {
				memberAddressDelete({id:data.Id}).then(() => {
					ElMessage({ type: 'success', message: '删除成功',duration:1000})
					getAddressList()
				})
			})
		}
	
	
	//新增收货地址
	const addAddress = ()=>{
		addressVisible.value = true;
		addressForm.mode='add';
		addressForm.province='';
		addressForm.city='';
		addressForm.area='';
		addressForm.street='';
	}
	
	//修改收货地址
	const editAddress = (data) => {
		addressVisible.value = true
		memberAddressInfo({id:data.Id}).then(res=>{
			let memberAddressInfo=res.data.memberAddressInfo;
			addressForm.id=data.Id
			addressForm.memberId=data.MemberId
			addressForm.linkMan=data.LinkMan
			addressForm.phone=data.Phone
			addressForm.province=data.Province
			addressForm.city=data.City
			addressForm.area=data.Area
			addressForm.street=data.Street
			addressForm.detailAddr=data.DetailAddr
			addressForm.isDefault=data.IsDefault
			addressForm.mode='edit'
		})
	}
	
	/**
	 * 取客户经理信息
	 * @param {无}
	 */
	const customerManager = reactive({
		tel: '',
	})
	
	const getMyAccountManager = async () => {
		const {
			data: {
				productManagerInfo
			}
		} = await getMyProductManager()
		customerManager.tel = productManagerInfo.tel;
	}
	
	//保存信息
	const submitAddress = (form) => {
		form.validate((valid, fields) => {
			if (valid) {
				let res = {
					mode:addressForm.mode,
					id:addressForm.id,
					memberId:addressForm.memberId,
					linkMan: addressForm.linkMan,
					phone: addressForm.phone,
					province: addressForm.province,
					city: addressForm.city,
					area: addressForm.area,
					street: addressForm.street,
					detailAddr: addressForm.detailAddr,
					isDefault:addressForm.isDefault
				}
				getMyAccountManager();
				memberAddressSave(res).then(() => {
					ElMessageBox({
						showClose: false,
						title: '温馨提示',
						message: '保存成功，待客户经理审核后可用！客户经理联系方式：' +customerManager.tel,
					})
					addressVisible.value = false
					getAddressList()
				})
			}
		})
	};
	
	//关闭弹窗重置表单
	const closeDialog=()=>{
		addressVisible.value = false;
		ruleFormRef.value.resetFields();
	}
	
	
</script>

<style lang="scss" scoped>
	.u-color-primary{
		color:#fe5ba3;
		font-weight: bold;
	}
	.u-color-red{
		color:red;
		font-weight: bold;
	}
</style>