<template>
  <div class="selected-tag u-m-t-5 u-flex-row" v-if="isShowBread">
    <div class="selected u-m-r-15" v-if="label != ''">
      <text>{{ show_label() }}<i class="iconfont icon-shanchu" @click="handleDeleteBreadItem('label')"></i></text>
    </div>
    <div class="selected u-m-r-15" v-if="main_category != ''">
      <text>大类：</text>
      <text>
        {{ main_category }}
        <i class="iconfont icon-shanchu" @click="handleDeleteBreadItem('main_category')"></i>
      </text>
    </div>
    <div class="selected u-m-r-15" v-if="sub_category != ''">
      <text>小类：</text>
      <text>
        {{ sub_category }}
        <i class="iconfont icon-shanchu" @click="handleDeleteBreadItem('sub_category')"></i>
      </text>
    </div>
    <div class="selected u-m-l-15" v-if="brand != ''">
      <text>品牌：</text>
      <text v-for="(item, index) in brand.split(',')" :key="index">
        {{ item }}<i class="iconfont icon-shanchu" @click="handleDeleteBreadItem('brand', item)"></i>
      </text>
    </div>
    <div class="selected u-m-l-15" v-if="color != ''">
      <text>颜色：</text>
      <text class="u-p-r-10" v-for="(item, index) in color.split(',')" :key="index">
        {{ item }}<i class="iconfont icon-shanchu" @click="handleDeleteBreadItem('color', item)"></i>
      </text>
    </div>
  </div>
</template>

<script setup>
import { getProductLabelList } from '@/api/productSale';
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps({
  main_category: {
    type: String,
    default: ''
  },
  sub_category: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  brand: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  }
});

onMounted(() => {
  getLabelList();
});

const label_list = ref([]);

const show_label = () => {
  let idx = label_list.value.findIndex(item => item.Id == props.label);
  if (idx == -1) return '';
  return label_list.value[idx].Name;
};

const isShowBread = computed(() => {
  return props.main_category != '' || props.label != '' || props.brand != '' || props.color != '';
});

const emit = defineEmits([
  'update:label',
  'update:main_category',
  'update:sub_category',
  'update:brand',
  'update:color'
]);

/**
 * 根据面包屑类型删除指定内容
 */
function handleDeleteBreadItem(type, value) {
  let arr = [];
  switch (type) {
    case 'label':
      emit('update:label', '');
      break;
    case 'main_category':
      emit('update:main_category', '');
      emit('update:sub_category', '');
      break;
    case 'sub_category':
      emit('update:sub_category', '');
      break;
    case 'brand':
      arr = props.brand.split(',');
      arr = arr.filter(item => item != value);
      emit('update:brand', arr.join(''));
      break;
    case 'color':
      arr = props.color.split(',');
      arr = arr.filter(item => item != value);
      emit('update:color', arr.join(','));
      break;
    default:
      break;
  }
}

/**
 * 获取标签信息
 */

const getLabelList = async () => {
  try {
    const {
      data: { records }
    } = await getProductLabelList();
    label_list.value = records.filter(item => item.ProductCount > 0);
  } catch (error) {
    console.log(error);
  }
};
</script>
