<template>
	<div class="common-layout">
		<el-container>
			<el-aside width="200px" class="aside-box">
				<div class="user-info-box">
					<div class="user-name">{{memberInfo.name}}</div>
					<div class="user-code">{{memberInfo.code}}</div>
					<div class="member-type">{{memberInfo.memberType}}</div>
					<div class="user-code" v-if="memberInfo.memberType=='普通会员'&&getApplyTime()<60&&getExpiration()>0">距试用期结束还有<span class="expiration">{{getExpiration()}}</span>天</div>
					<div class="user-code" v-if="memberInfo.memberType=='普通会员'&&getApplyTime()>=60&&getExpiration()<=30&&getExpiration()>0">距离会员到期还有<span class="expiration">{{getExpiration()}}</span>天</div>
					<div class="user-code" v-if="memberInfo.memberType=='普通会员'&&getApplyTime()>=60&&getExpiration()<=0"><span class="expiration">年费已到期</span></div>
				</div>
				<ul class="nav-box">
					<li>
						<router-link to="/index/personalCenter/accountInfo" active-class="selected-link"><i
								class="iconfont icon-yue"></i>账号信息</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/logistics" active-class="selected-link"><i
								class="iconfont icon-wuliu-"></i>订单物流</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/orderSearch" active-class="selected-link"><i
								class="iconfont icon-dingdan1"></i>订单查询</router-link>
					</li>
          <li>
            <router-link to="/index/personalCenter/orderComplaint" active-class="selected-link"><i
                class="iconfont icon-letter"></i>订单售后</router-link>
          </li>
					<li>
						<router-link to="/index/personalCenter/preSaleSearch" active-class="selected-link"><i
								class="iconfont icon-dingdan"></i>预定查询</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/billSearch" active-class="selected-link"><i
								class="iconfont icon-zhangdan"></i>账单查询</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/withdrawRecord" active-class="selected-link"><i
								class="iconfont icon-zhangdan"></i>提现记录</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/modifyPassword" active-class="selected-link"><i
								class="iconfont icon-mima"></i>修改密码</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/personalInfo" active-class="selected-link"><i
								class="iconfont icon-wode-gerenziliao"></i>我的资料</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/shipAddress" active-class="selected-link"><i
								class="iconfont icon-dizhi"></i>收货地址</router-link>
					</li>
					<li>
						<router-link to="/index/personalCenter/invoiceCenter" active-class="selected-link"><i
								class="iconfont icon-yewukanban"></i>发票中心</router-link>
					</li>
          <li>
            <router-link to="/index/personalCenter/news" active-class="selected-link"><i
                class="iconfont icon-gonggao"></i>物流信息</router-link>
          </li>
          <li>
            <router-link to="/index/personalCenter/vipIntroduction" active-class="selected-link"><i
                class="iconfont icon-dingdan-copy"></i>Vip攻略</router-link>
          </li>
          <li>
            <router-link to="/index/personalCenter/newbie" active-class="selected-link"><i
                class="iconfont icon-dingdan-copy"></i>新手指南</router-link>
          </li>
          <li>
            <router-link to="/index/personalCenter/afterSalesRules" active-class="selected-link"><i
                class="iconfont icon-dingdan-copy"></i>售后规则</router-link>
          </li>
          <li>
            <router-link to="/index/personalCenter/messageList" active-class="selected-link"><i
                class="iconfont icon-letter"></i>意见建议</router-link>
          </li>
				</ul>
			</el-aside>
			<el-main style="margin-bottom:60px;background-color: #fff;">
				<router-view></router-view>
			</el-main>
		</el-container>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,onMounted
	} from 'vue';
	import {
		getMemberInfo
	} from '@/api/user';
	import {
		userStore
	} from '@/store/user';
	//import moment from 'moment'
	onMounted(async () => {
		await memberInfoDetails()
	});
	/**
	 * 取用户信息
	 * @param {无}
	 */
	const memberInfo = reactive({
		name: '',
		code: '',
		memberType:'',//会员类型
		recentTime:'',//会员到期时间
		applyTime:'',//会员注册时间

		
	});
	//获取会员信息
	const memberInfoDetails = () => {
		let id = userStore().memberInfo.memberId;
		return getMemberInfo({}).then(res => {
			let info=res.data.memberInfo;
			memberInfo.id=info.id;
			memberInfo.code=info.code;
			memberInfo.name=info.name;
			memberInfo.memberType=info.memberType;//会员类型
			memberInfo.recentTime=info.recentTime;//到期时间
			memberInfo.applyTime=info.applyTime;//注册时间
		})
	};
	//计算到期时间
	const getExpiration=()=>{
		let recentTime=new Date(memberInfo.recentTime);
		let currentTime=new Date();
		let expirationTime =recentTime-currentTime;
		let expirationDays=Math.floor(expirationTime/(24*3600*1000));
		return expirationDays
	}
	//计算当前时间-注册时间相隔天数
	const getApplyTime=()=>{
		let applyTime=new Date(memberInfo.applyTime);
		let currentTime=new Date();
		let diffTime =currentTime-applyTime;
		let diffDays=Math.floor(diffTime/(24*3600*1000));
		return diffDays
	}
</script>

<style lang="scss" scoped>
	.aside-box {
		background-color: #F8F9FD;
		text-align: center;

		.user-info-box {
			border-bottom: 1px solid #E2E2E2;
			padding:10px 15px;
			.user-name {
				font-size: 21px;
				font-weight: 400;
				color: #434343;
				line-height: 42px;
			}

			.user-code {
				font-size: 17px;
				font-weight: 400;
				color: #666666;
				line-height: 34px;
				.expiration{
					font-weight: bold;
					color:#FE5BA3;
				}
			}
			.member-type{
				font-weight: bold;
				color:#FE5BA3;
			}
			
		}

		.nav-box {
			text-align: center;
			margin-top: 5px;

			li {
				padding: 5px 0px;
				display: flex;
				justify-content: center;

				a {
					display: block;
					font-size: 18px;
					font-weight: 400;
					color: #505050;
					width: 180px;
					height: 32px;
					line-height: 32px;
					border-radius: 40px;
					text-align: center;

					i {
						font-size: 18px;
						margin-right: 10px;
					}
				}

				.selected-link {
					background: #FE5BA3;
					color: #FFFFFF;
				}
			}
		}

	}
</style>