<template>
	<!-- 表格 -->
	<el-button type="primary" class="u-m-b-15" @click="addInvoiceInfo">新增抬头</el-button>
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="MemCode" label="会员号" align="center" width="60"></el-table-column>
		<el-table-column prop="InvoiceTitle" label="发票抬头" align="center" width="100"></el-table-column>
		<el-table-column prop="TaxNum" label="税号" align="center"></el-table-column>
		<el-table-column prop="Email" label="邮箱" align="center"></el-table-column>
		<!-- <el-table-column prop="Address" label="注册地址" align="center"></el-table-column>
		<el-table-column prop="Phone" label="注册电话" align="center"></el-table-column>
		<el-table-column prop="BankName" label="开户行" align="center"></el-table-column>
		<el-table-column prop="BankAccount" label="银行账号" align="center"></el-table-column> -->
		<el-table-column prop="State" label="状态" align="center">
			<template #default="scope">
				<span v-if="scope.row.State==0">待审核</span>
				<span v-if="scope.row.State==1" style="color:green">审核通过</span>
				<span v-if="scope.row.State==2" style="color:red;">审核不通过</span>
			</template>
		</el-table-column>
		<el-table-column prop="TitleType" label="类型" align="center" width="50">
			<template #default="scope">
				<span v-if="scope.row.TitleType==1">个人</span>
				<span v-if="scope.row.TitleType==2">单位</span>
			</template>
		</el-table-column>
		<el-table-column prop="operation" label="操作" align="center" width="50">
			<template #default="scope">
				<el-button text @click="toDetails(scope.row.Id)" style="padding:0px;color:#38adff">查看</el-button>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
	<invoice-title-info ref="invoiceInfoRef"></invoice-title-info>
	<add-invoice-title-info ref="addInvoiceTitleRef"></add-invoice-title-info>
</template>

<script setup>
	import {reactive,ref,onMounted} from 'vue';
	import {webMemberInvoiceTitleList} from '@/api/user';
	import moment from 'moment'
	import invoiceTitleInfo from './invoiceTitleInfo.vue'
	import addInvoiceTitleInfo from './addInvoiceInfo.vue'
	onMounted(async () => {
		await initData();
	})
	
	//数据源
	const state = reactive({
		params: {
			InvoiceState:0,
			limit: {
				enable: true,
				conditions: {
					pageLength: 10,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})
	
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getApplyInvoiceList()
	}
	/**
	 * 获取抬头列表
	 * @param {无}
	 */
	const getApplyInvoiceList = () => {
		state.loading = true
		return webMemberInvoiceTitleList(state.params).then(res => {
			const {data: {filterAmount,records}} = res
			state.data = records.map(item => {
				return {...item}
			})
			state.total = filterAmount
			state.loading = false
		})
	}
	
	
	// 抬头详情
	const invoiceInfoRef = ref(false);
	const toDetails = (Id) =>{
		invoiceInfoRef.value.onChangeVisible(Id);
	}
	
	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getApplyInvoiceList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getApplyInvoiceList()
	};
	
	
	//新增抬头
	const addInvoiceTitleRef = ref(false);
	const addInvoiceInfo = () => {
		addInvoiceTitleRef.value.onChangeAddTitleVisible()
	};
</script>

<style lang="scss" scoped>
	.add-btn{
		text-align: right;
		margin-bottom: 15px;
	}
</style>