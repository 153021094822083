import {
	defineStore
} from 'pinia';
import {
	accountLogin,
	phoneLogin,
	memberInfoDetail,
	memberAddressList
} from '@/api/user'
import {
	getMyProductManager
} from '@/api/productSale';

export const userStore = defineStore('userStore', {
	persist: {
		enabled: true, //开启数据持久化
		strategies: [{
			key: 'managestate',
			storage: localStorage, 
		}]
	},
	state: () => ({
		code: 0,
		// 用户信息
		memberInfo: {
			memberId:'',
			memberName: '',
			memberCode: '',
			memberPhone: '',
			memberToken: '',
			amount: 0,//余额
			monetary: 0//可用余额
		},
		// 会员详情信息
		memberDetailInfo: {},
		// 会员收获地址
		addressList: [],
		// 会员的客户经理信息
		customerManager: {}
	}),
	getters: {
		// memberDetailInfo: (state) => state.memberDetailInfo
	},
	actions: {
		setUserInfo(){
			memberInfo:localStorage.setItem('memberInfo');
		},
		// 账号登录
		async accountLogin(accountLoginForm) {
			const {
				code,
				data
			} = await accountLogin(accountLoginForm);
			this.code = code;
			if(this.code == 0){
				this.memberInfo = data.memberInfo;
				await this.initData()	
			}
		},
		// 手机号登录
		async phoneLogin(phoneLoginForm) {
			const {
				code,
				data
			} = await phoneLogin(phoneLoginForm);
			this.code = code;
			this.memberInfo = data.memberInfo;
			await this.initData()
		},
		// 初始化信息
		async initData() {
			await this.getMemberInfo()
			await this.getAddressList()
			await this.getMyAccountManager()
		},
		/**
		 * 获取会员详情信息
		 * @param {无}
		 */
		async getMemberInfo(){
			const { data } = await memberInfoDetail()
			this.memberDetailInfo = data.memberInfo
		},
		/**
		 * 取会员收获地址
		 * @param {无}
		 */
		async getAddressList() {
			const { data: { records } } = await memberAddressList()
			this.addressList = records
		},
		/**
		 * 取会员的客户经理信息
		 */
		async getMyAccountManager() {
			const { data: { productManagerInfo } } = await getMyProductManager()
			this.customerManager = productManagerInfo
		}
	}
})