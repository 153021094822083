<template>
  <div v-show="show">
    <el-table
      :data="state.list"
      v-loading="state.loading"
      border
      empty-text="没有找到相关信息！"
      size="small"
      show-summary
      table-layout="auto"
      style="width: 100%"
      class="u-m-b-15"
    >
      <el-table-column label="品名" align="center" header-align="center">
        <template #default="scope">{{ scope.row.Brand + scope.row.ProductName + ' ' + scope.row.Grade }}</template>
      </el-table-column>
      <el-table-column label="日期" align="center">
        <template #default="scope">
          <span>{{ moment(scope.row.SaleDate).format('YYYY-MM-DD') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格" align="center" width="40">
        <template #default="scope">{{ scope.row.ProductPrice }}</template>
      </el-table-column>
      <el-table-column label="数量" align="center" prop="OrderCount" width="80">
        <template #default="scope">
          <el-input-number
            style="width: 80px"
            size="small"
            v-model="scope.row.OrderCount"
            :min="0"
            v-if="scope.row.CanModify == 1"
            @change="onEditOrder(scope.row)"
          ></el-input-number>
          <span v-else>{{ scope.row.OrderCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="佣金" align="center" prop="Commission" width="40">
        <template #default="scope">{{ scope.row.Commission.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="金额" align="center" prop="OrderAmount" width="40">
        <template #default="scope">{{ scope.row.OrderAmount.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="60">
        <template #default="scope">
          <span v-if="scope.row.CanModify == 1">
            <!-- <i style="font-size: 20px" class="iconfont icon-bianji" @click="onEditOrder(scope.row)"></i> -->
            <i style="font-size: 20px" class="iconfont icon-shanchu1" @click="onDeleteOrder(scope.row)"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="sum-box">
      <div v-if="state.totalize.ShippingTitle">
        运费方案：{{ state.totalize.ShippingTitle }}（{{ memberInfo.logistics }}-{{ memberInfo.addressCity }}）
      </div>
      <div v-if="state.totalize.PredictTitle">
        {{ [0, 2].includes(state.totalize.ShippingType) ? '预估重量' : '预估件' }}：{{ state.totalize.PredictTitle }}
      </div>
      <div v-if="state.totalize.MainTotalDeliveryFee">预估运费：{{ state.totalize.MainTotalDeliveryFee }}</div>
      <div v-if="state.totalize.MainTotalPackingFee">预估配货装箱费：{{ state.totalize.MainTotalPackingFee }}</div>
      <div v-if="state.totalize.TotalDiscount">折扣：{{ state.totalize.TotalDiscount }}</div>
      <div v-if="state.totalize.TotalGiftOuantity">赠送数量：{{ state.totalize.TotalGiftOuantity }}</div>
      <div v-if="state.totalize.MainOrderActualAmount"
        >预估金额：
        <span style="color: red; font-weight: bold">{{ state.totalize.MainOrderActualAmount }}</span>
      </div>
    </div>
    <div class="tips-box">
      <el-button class="blue-btn u-m-b-15">
        <router-link to="/index/personalCenter/preSaleSearch" style="color: #ffffff">查看历史预订单</router-link>
      </el-button>
      <div class="tips">
        <text>预订操作指引：</text>明天区及明天以后的产品是提前预售。(可选择发货日期来下单当天的货品)。
      </div>
      <div class="tips"
        >1、发货前一天12：00之前下单货品显示在预售区。发货前一天12:30时，当日预订单会转为已购货单，与当天订购货品一同生成订单并发货。</div
      >
      <div class="tips">2、预订区货品下单10分钟内可以修改或取消订单。超时不可取消和修改。</div>
      <!-- <div class="tips">3、后天区、大后天区以及4天后的预订区货品下单2小时内修改可以修改或取消订单。</div>
		<div class="tips">4、预订单如有数量不足或者等级调换，客户经理会提前联系。</div> -->
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, computed, onMounted, watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import moment from 'moment';
import { getBookOrderDetailList, updateBookOrderDetailSave } from '@/api/productSale';
import { userStore } from '@/store/user';

const userInfo = userStore();

const props = defineProps({
  memberInfo: {
    type: Object,
    default: {}
  },
  show: {
    type: Boolean,
    default: false
  }
});

/**
 * 取预购订单列表
 * @param {无}
 */
const state = reactive({
  loading: true,
  list: [],
  totalize: {}
});

onMounted(() => {
  getBookOrderList();
});

watch(
  () => props.show,
  (newValue, oldValue) => {
    if (newValue) {
      getBookOrderList();
    }
  }
);

const getBookOrderList = async () => {
  Object.keys(state.totalize).forEach(key => (state.totalize[key] = 0));
  const {
    data: { records }
  } = await getBookOrderDetailList();
  state.list = records;
  state.totalize = { ...state.totalize, ...[...records].shift() };
  state.loading = false;
};

/**
 * 是否显示修改删除键
 * @param {object} event
 */
// const isShow = computed(() => {
// 	return function(event) {
// 		if (event.SaleType == 3) {
// 			return moment(moment().format('YYYY-MM-DD HH:mm')).diff(moment(event.OrderTime), 'minutes') < 10 || event.CanModify == 1
// 		} else {
// 			return moment(moment().format('YYYY-MM-DD HH:mm')).diff(moment(event.OrderTime), 'minutes') < 120 || event.CanModify == 1
// 		}
// 	}
// })

/**
 * 修改订单
 * @param {object} event
 */
const onEditOrder = async event => {
  if (!event.OrderCount) {
    ElMessage({ message: '数量不能为空', type: 'warning' });
    return;
  }
  const params = { id: event.Id, orderCount: event.OrderCount };
  const { code } = await updateBookOrderDetailSave(params);
  if (code == 0) {
    ElMessage({ type: 'success', message: `${event.OrderCount == 0 ? '删除成功' : '修改成功'}` });
    getBookOrderList();
    userInfo.getMemberInfo();
  }
};

/**
 * 删除订单
 * @param {object} event
 */
const onDeleteOrder = event => {
  ElMessageBox.alert('确定删除该订单吗？', '提示', {
    type: 'warning',
    cancelButtonText: '取消',
    confirmButtonText: '确定'
  }).then(async () => {
    const params = { id: event.Id, orderCount: 0 };
    const { code } = await updateBookOrderDetailSave(params);
    if (code == 0) {
      ElMessage({ type: 'success', message: '删除成功' });
      getBookOrderList();
      userInfo.getMemberInfo();
    }
  });
};

function updateData(params) {
  getBookOrderList();
}

defineExpose({ updateData });
</script>

<style lang="scss" scoped>
@import '@/assets/style/same.scss';
</style>
