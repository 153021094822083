<template>
	<div class="title-wrap">
		<div class="title">订单售后</div>
		<div class="tips">截单后96小时内都可售后！</div>
	</div>
	<!-- 搜索栏 -->
	<el-form ref="formRef" :model="searchForm" inline>
		<el-form-item label="日期：" prop="saleDate">
			<el-date-picker v-model="searchForm.saleDate" type="daterange" start-placeholder="开始时间"
				end-placeholder="结束时间" range-separator="至" :default-value="[new Date()]"
				@change="change(searchForm.saleDate)" />
		</el-form-item>
		<el-form-item label="品牌：" prop="brand">
			<el-input v-model="state.params.brand" placeholder="请输入品牌" style="width:120px;" clearable @change="changeInputVal"></el-input>
		</el-form-item>
		<el-form-item label="品名：" prop="productName">
			<el-input v-model="state.params.productName" placeholder="请输入品名" style="width:120px;" clearable @change="changeInputVal"></el-input>
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="getOrderComplainList">查询</el-button>
			<el-button type="primary" @click="getComplainList" style="margin-left:15px !important;">我的售后</el-button>
		</el-form-item>
	</el-form>
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="SaleDate" label="下单日期" align="center">
			<template #default="scope">
				<span>{{ moment(scope.row.SaleDate).format('YYYY-MM-DD') }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="SubmitTime" label="截单日期" align="center"></el-table-column>
		<el-table-column prop="OrderNo" label="单号" align="center"></el-table-column>
		<el-table-column prop="Brand,ProductName,Grade" label="品牌|品名|等级" align="center">
			<template #default="scope">
				<span>{{scope.row.Brand+scope.row.ProductName+scope.row.Grade}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="ProductPrice" label="单价" align="center">
			<template #default="scope">
				<span>{{scope.row.ProductPrice.toFixed(2)}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="OrderCount" label="订购量" align="center"></el-table-column>
		<el-table-column prop="OrderLackCount" label="缺货量" align="center"></el-table-column>
		<el-table-column prop="EffectiveTotalCount" label="有效量" align="center"></el-table-column>
		<el-table-column prop="OrderMoney" label="订购额" align="center">
			<template #default="scope">
				<span>{{scope.row.OrderMoney.toFixed(2)}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="OrderLacklAmount" label="缺货额" align="center">
			<template #default="scope">
				<span>{{scope.row.OrderLacklAmount.toFixed(2)}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="EffectiveTotalAmount" label="有效额" align="center">
			<template #default="scope">
				<span>{{scope.row.EffectiveTotalAmount.toFixed(2)}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="operation" label="售后" align="center">
			<template #default="scope">
				<span v-if="scope.row.ComplainStatus=='未到售后期'">未到售后期</span>
				<span v-if="scope.row.ComplainStatus=='售后已过期'">售后已过期</span>
				<span v-if="scope.row.ComplainStatus=='已赔付'">已赔付</span>
				<el-button v-if="scope.row.ComplainStatus=='待确认'" @click="toApplyComplaint(scope.row)">待确认</el-button>
				<el-button v-if="scope.row.ComplainStatus=='申请售后'" type="primary" @click="toApplyComplaint(scope.row)">申请售后</el-button>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
	<apply-complaint ref="applyComplaintRef"  @update="getOrderComplainList"></apply-complaint>
	<complaint-list-dialog ref="complaintListRef"></complaint-list-dialog>
</template>

<script setup>
	import {reactive,ref,onMounted} from 'vue';
	import {webOrderListForComplain} from '@/api/user';
	import moment from 'moment'
	import applyComplaint from './applyComplaintDialog.vue'
	import complaintListDialog from './complaintListDialog.vue'
	onMounted(async () => {
		await initData();
	})

	const searchForm = reactive({
		saleDate: '',
		brand:'',
		productName:''
	});
	//数据源
	const state = reactive({
		params: {
			startSaleDate:'',
			endSaleDate:'',
			productName:'',
			brand:'',
			limit: {
				enable: true,
				conditions: {
					pageLength: 15,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})

	const change = (val) => {
		if (val) {
			const v = JSON.parse(JSON.stringify(val))
			state.params.startSaleDate = moment(v[0]).format('YYYY-MM-DD HH:mm:ss')
			state.params.endSaleDate = moment(v[1]).format('YYYY-MM-DD HH:mm:ss')
			
		} else {
			state.params.startSaleDate = ''
			state.params.endSaleDate = ''
			getOrderComplainList()
		}
		
	}
	const changeInputVal=()=>{
		getOrderComplainList()
	}
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getOrderComplainList()
	}
	/**
	 * 获取已收货订单列表
	 * @param {无}
	 */
	const getOrderComplainList = () => {
		state.loading = true
		return webOrderListForComplain(state.params).then(res => {
			const {data: {filterAmount,records}} = res
			state.data = records.map(item => {
				return {...item}})
			state.total = filterAmount
			state.loading = false
		})
	}

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getOrderComplainList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getOrderComplainList()
	};
	
	//我的售后(售后列表)
	const complaintListRef = ref();
	const getComplainList=()=>{
		complaintListRef.value.onChangeComplainListVisible();
	}
	//申请售后
	const applyComplaintRef = ref(false);
	const toApplyComplaint=(event)=>{
		const params = {
			Id:event.Id,
			OrderNo:event.OrderNo,
			Brand:event.Brand,
			ProductName:event.ProductName,
			Grade:event.Grade,
			EffectiveTotalCount:event.EffectiveTotalCount,
			ProductPrice:event.ProductPrice,
			OrderComplainId:event.OrderComplainId
		}
		applyComplaintRef.value.onChangeVisible(params);
	}
	
</script>

<style lang="scss" scoped>
	.title-wrap{
		display:flex;
		font-size: 18px;
		font-weight: bold;
		justify-content: flex-start;
		align-items: center;
		line-height:50px;
		.title {
			color: #3A3A3A;
		}
		.tips {
			margin-left:20px;
			color: red;
		}
	}
	
</style>