import request from '@/utils/request';

// 取我看花列表
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-79573153
export const webShareProductList = (data) => {
	return request.post('/api/shareProduct/webShareProductList', data)
}

// 我要分享
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-79573549
export const webShareProductSave = (data) => {
	return request.post('/api/shareProduct/webShareProductSave', data)
}

// 取我看花的关联产品列表
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-79782026
export const webShareRelatedProductList = (data = {}) => {
	return request.post('/api/shareProduct/webShareRelatedProductList', data)
}

// 我看花点赞/取消点赞接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-79782153
export const webLikeCountSave = (data) => {
	return request.post('/api/shareProduct/webLikeCountSave', data, { headers: { showLoading: true } })
}
