<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="我的投诉" width="1200px">
		<el-table :data="state.data" border style="width: 100%" v-loading="state.loading" empty-text="没有找到相关信息！"
			:header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
			<el-table-column prop="CreateTime" label="申请时间" align="center">
				<template #default="scope">
					<span>{{moment(scope.row.CreateTime).format('YYYY-MM-DD')}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="Brand" label="品牌" align="center"></el-table-column>
			<el-table-column prop="ProductName" label="品名" align="center"></el-table-column>
			<el-table-column prop="Grade" label="等级" align="center"></el-table-column>
			<el-table-column prop="Color" label="颜色" align="center"></el-table-column>
			<el-table-column prop="OrderPrice" label="单价" align="center"></el-table-column>
			<el-table-column prop="ComplainQuanlity" label="投诉数量" align="center"></el-table-column>
			<!-- <el-table-column prop="CompensateReason" label="赔付原因" align="center"></el-table-column> -->
			<el-table-column prop="CompensateTotalCount" label="赔付数量" align="center" ></el-table-column>
			<el-table-column prop="CompensateTotalMoney" label="赔付金额 " align="center">
				<template #default="scope">
					<span>{{ scope.row.CompensateTotalMoney.toFixed(2) }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="CompensateTime" label="赔付时间" align="center"></el-table-column> -->
			<el-table-column prop="StatusStr" label="状态 " align="center"></el-table-column>
			<el-table-column prop="operation" label="操作" align="center">
				<template #default="scope">
					<el-button text @click="toDetails(scope.row)" style="padding:0px;color:#38adff">查看详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<apply-complaint-details ref="applyComplaintDetailsRef"></apply-complaint-details>
	</el-dialog>
</template>

<script setup>
	import {reactive,ref,onMounted} from 'vue';
	import {webOrderComplainList} from '@/api/user';
	import moment from 'moment';
	import applyComplaintDetails from './applyComplaintDetails.vue'
	//订单详情数据源
	const state = reactive({
		data: [], //表格数据
		loading: true
	})
	/**
	 * 获取售后列表
	 * @param {无}
	 */
	const getOrderDetailList = () => {
		state.loading = true;
		return webOrderComplainList({}).then(res => {
			state.data = res.data.records.map(item => {
				return {
					...item
				}
			})
			state.loading = false;
		})

	}

	const dialogVisible = ref(false);
	const onChangeComplainListVisible = () => {
		dialogVisible.value = true;
		getOrderDetailList();
	};

	defineExpose({
		onChangeComplainListVisible
	});
	
	//查看详情
	const applyComplaintDetailsRef = ref();
	const toDetails=(event)=>{
		const params = {
			Id:event.Id,
			Brand:event.Brand,
			ProductName:event.ProductName,
			Grade: event.Grade
		}
		applyComplaintDetailsRef.value.onChangeVisible(params);
	}
</script>

<style lang="scss" scoped>
</style>