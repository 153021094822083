import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import userStore from '@/store/user'

// 导入页面组件
import Index from '@/views/index/index.vue';
import Home from '@/views/home/index.vue';
import Login from '@/views/login/login.vue'
import Notice from '@/views/notice/index.vue'
import OrderProduct from '@/views/orderProduct/index.vue'
import Flower from '@/views/flower/index.vue'
import ShoppingCart from '@/views/shoppingCart/index.vue'
import PersonalCenter from '@/views/personalCenter/index.vue'
import AccountInfo from '@/views/accountInfo/index.vue'
import OrderSearch from '@/views/orderSearch/index.vue'
import PreSaleSearch from '@/views/preSaleSearch/index.vue'
import BillSearch from '@/views/billSearch/index.vue'
import ModifyPassword from '@/views/modifyPassword/index.vue'
import PersonalInfo from '@/views/personalInfo/index.vue'
import ShipAddress from '@/views/shipAddress/index.vue'
import OrderComplaint from '@/views/orderComplaint/index.vue'
import MessageList from '@/views/messageList/messageList.vue'
import InvoiceCenter from '@/views/invoiceCenter/index.vue'
import News from '@/views/news/index.vue'
import AfterSalesRules from '@/views/afterSalesRules/index.vue'
import Logistics from '@/views/logistics/index.vue'
import Share from '@/views/flower/share.vue'
import Register from '@/views/register/index.vue'
import ExperienceAccount from '@/views/login/experienceAccount.vue'
import RetrievePassword from '@/views/login/retrievePassword.vue'
import ProDetails from '@/views/proDetails/index.vue'
import AccountLogin from '@/views/login/login/account.vue'
import PhoneLogin from '@/views/login/login/phone.vue'
import ArticleDetails from '@/views/articleDetails/index.vue'
import VipIntroduction from '@/views/vipIntroduction/index.vue'
import Newbie from '@/views/newbie/index.vue'
import InvoiceApplyList from '@/views/invoiceCenter/invoiceApplyList.vue'
import InvoiceList from '@/views/invoiceCenter/invoiceList.vue'
import InvoiceInfoList from '@/views/invoiceCenter/invoiceInfoList.vue'
import WithdrawRecord from '@/views/withdrawRecord/index.vue'


const routes = [{
		path: '/',
		redirect: '/login',
		name: 'Login',
		component: ExperienceAccount,
		children: [{
				path: 'login',
				name: 'Login',
				component: Login,
				redirect: '/login/account',
				children: [{
						path: 'account',
						name: 'AccountLogin',
						component: AccountLogin
					},
					{
						path: 'phone',
						name: 'PhoneLogin',
						component: PhoneLogin
					},
				]
			},
			{
				path: 'register',
				name: 'Register',
				component: Register
			},
			{
				path: 'retrievePassword',
				name: 'RetrievePassword',
				component: RetrievePassword
			}
		]
	},
	{
		path: '/notice',
		name: 'Notice',
		component: Notice,
	},
	{
		path: '/index',
		name: 'Index',
		component: Index,
		redirect: '/index/home',
		children: [{
				path: 'home',
				name: 'Home',
				component: Home
			},
			{
				path: 'orderProduct',
				name: 'OrderProduct',
				component: OrderProduct
			},
			{
				path: '/proDetails',
				name: 'ProDetails',
				component: ProDetails
			},
			{
				path: 'flower',
				name: 'Flower',
				component: Flower,
			},
			{
				path: 'share',
				name: 'Share',
				component: Share
			},
			{
				path: '/shoppingCart',
				name: 'ShoppingCart',
				component: ShoppingCart
			},
			{
				path: 'personalCenter',
				name: 'PersonalCenter',
				component: PersonalCenter,
				redirect: '/index/personalCenter/accountInfo',
				children: [{
						path: 'accountInfo',
						name: 'AccountInfo',
						component: AccountInfo
					},
					{
						path: 'orderSearch',
						name: 'OrderSearch',
						component: OrderSearch
					},
					{
						path: 'afterSalesRules',
						name: 'AfterSalesRules',
						component: AfterSalesRules
					},
					{
						path: 'news',
						name: 'News',
						component: News
					},
					{
						path: 'logistics',
						name: 'Logistics',
						component: Logistics
					},
					{
						path: 'preSaleSearch',
						name: 'PreSaleSearch',
						component: PreSaleSearch
					},
					{
						path: 'billSearch',
						name: 'BillSearch',
						component: BillSearch
					},
					{
						path: 'withdrawRecord',
						name: 'WithdrawRecord',
						component: WithdrawRecord
					},
					{
						path: 'modifyPassword',
						name: 'ModifyPassword',
						component: ModifyPassword
					},
					{
						path: 'personalInfo',
						name: 'PersonalInfo',
						component: PersonalInfo
					},
					{
						path: 'shipAddress',
						name: 'ShipAddress',
						component: ShipAddress
					},
					{
						path: 'orderComplaint',
						name: 'OrderComplaint',
						component: OrderComplaint
					},
					{
						path: 'messageList',
						name: 'MessageList',
						component: MessageList
					},
					{
						path: 'invoiceCenter',
						name: 'InvoiceCenter',
						component: InvoiceCenter,
						redirect: '/index/personalCenter/invoiceCenter/invoiceApplyList',
						children: [{
								path: 'invoiceApplyList',
								name: 'InvoiceApplyList',
								component: InvoiceApplyList
							},
							{
								path: 'invoiceList',
								name: 'InvoiceList',
								component: InvoiceList
							},
							{
								path: 'invoiceInfoList',
								name: 'InvoiceInfoList',
								component: InvoiceInfoList
							}
						]
					},
					{
						path: 'vipIntroduction',
						name: 'VipIntroduction',
						component: VipIntroduction
					},
					{
						path: 'newbie',
						name: 'Newbie',
						component: Newbie
					}
				]
			}
		]
	},
	{
		path: '/articleDetails',
		name: 'ArticleDetails',
		component: ArticleDetails
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})


export default router