<template>
	<el-dialog v-model="vatInvoiceVisible" title="增值税专用发票抬头确认书" width="400px">
		<div class="u-font-line">根据国家税法及发票管理相关规定，任何单位和个人不得要求他人为自己开具与实际经营业务情况不符的增值税专用发票[包括并不限于(1) 在没有货物采购或者没有接受应税劳务的情况下要求他人为自己开具增值税专用发票;(2)
			虽有货物采购或者接受应税劳务但要求他人为自己开具数量或金额与实际情况不符的增值税专用发票]， 否则属于“虚开增值税专用发票”。</div>
		<div class="u-font-line">我已充分了解上述各项相关国家税法和发票管理规定，并确认仅就我司实际购买商品或服务索取发票。如我司未按国家相关规定申请开具或使用增值税专用发票，由我司自行承担相应法律后果</div>
		<div class="u-font-line">同意请点“确定”，不同意请点“取消”。</div>
		<template #footer>
		      <span class="dialog-footer">
		        <el-button size="large" @click="vatInvoiceVisible = false">取消</el-button>
		        <el-button type="primary" size="large" @click="vatInvoiceVisible = false" style="margin-left:15px !important;">确定</el-button>
		      </span>
		    </template>
	</el-dialog>
</template>

<script setup>
	import {ref} from 'vue';
	//打开弹窗时
	const vatInvoiceVisible = ref(false);
	const onChangeVatInvoiceVisible = () => {
		vatInvoiceVisible.value = true;
	};
	
	defineExpose({
		onChangeVatInvoiceVisible
	});
</script>

<style lang="scss" scoped>
	.u-font-line{
		font-size:16px;
		line-height:32px;
	}
</style>