<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="我的客户经理">
		<el-row :gutter="20">
		  <el-col :span="16" class="img-box">
				<el-image class="manager-img" :src="customerManager.headImg"></el-image>
				<el-image class="wechat-img u-m-l-100" :src="customerManager.weiXinImg"></el-image>
			</el-col>
		  <el-col :span="8" class="img-box img-border">
				<el-image class="wechat-img" :src="require('@/assets/image/WeChat1.jpg')"></el-image>
			</el-col>
		</el-row>
		<el-row :gutter="20">
		  <el-col :span="16">
				<div class="info">
					<div class="name">姓名：{{ customerManager.managerName }}</div>
					<div class="phone">手机：{{ customerManager.tel }}</div>
				</div>
			</el-col>
		  <el-col class="img-box" :span="8">
				<div class="info">客服微信</div>
			</el-col>
		</el-row>
	</el-dialog>
</template>

<script setup>
	import { ref } from "vue";
	import { userStore } from '@/store/user'

	const { customerManager } = userStore()

	const dialogVisible = ref(false);

	defineExpose({
		dialogVisible
	});
</script>

<style lang="scss" scoped>
	$img-width: 210px;

	.img-box{
		display: flex;
		align-items: flex-end;
		text-align: center;
	}
	.img-border {
		padding-left: 25px;
		border-left: 10px solid #eeeeee;
	}
	.manager-img{
		width:153px;
		height:$img-width;
	}
	.wechat-img{
		width: $img-width;
		height: $img-width;
	}
	.info{
		padding:50px 0px;
		font-size: 20px;
		font-weight: 400;
		color: #3A3A3A;
		line-height: 42px;
	}
	.u-m-l-100{
		margin-left: 100px;
	}
</style>
