<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="抬头信息" draggable width="550px"
		@close="closeDialog">
		<el-form :model="invoiceInfoForm" ref="ruleFormRef" :rules="rules" label-width="100px">
			<el-form-item label="发票类型：" prop="invoiceType" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.invoiceType" disabled>
					<el-radio :label="1">普票</el-radio>
					<el-radio :label="2">增值税专用票</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="抬头类型：" prop="titleType" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.titleType" disabled>
					<el-radio :label="1">个人</el-radio>
					<el-radio :label="2">单位</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票材质：" prop="invoiceMode" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.invoiceMode" disabled>
					<el-radio :label="1">电子版</el-radio>
					<!-- <el-radio :label="2">纸质版</el-radio> -->
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票抬头：" prop="invoiceTitle" class="u-m-b-15">
				<el-input type="text" v-model="invoiceInfoForm.invoiceTitle"></el-input>
			</el-form-item>
			<div v-if="invoiceInfoForm.titleType==2">
				<el-form-item label="单位税号：" prop="taxNum" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.taxNum"></el-input>
				</el-form-item>
				<!-- <el-form-item label="开户银行：" prop="bankName" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.bankName"></el-input>
				</el-form-item>
				<el-form-item label="银行账号：" prop="bankAccount" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.bankAccount"></el-input>
				</el-form-item>
				<el-form-item label="注册地址：" prop="address" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.address"></el-input>
				</el-form-item>
				<el-form-item label="注册电话：" prop="phone" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.phone"></el-input>
				</el-form-item> -->
			</div>
			<el-form-item label="邮箱：" prop="email" class="u-m-b-15">
				<el-input type="text" v-model="invoiceInfoForm.email"></el-input>
			</el-form-item>
			<!-- <div v-if="invoiceInfoForm.titleType==2&&invoiceInfoForm.invoiceMode==2">
				<el-form-item label="收票人姓名：" prop="deliveryContact"
					v-if="invoiceInfoForm.titleType==2&&invoiceInfoForm.invoiceMode==2" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.deliveryContact"></el-input>
				</el-form-item>
				<el-form-item label="收票地址：" prop="receiveAddress" class="u-m-b-15">
					<el-select v-model="invoiceInfoForm.province" filterable placeholder="省" class="u-m-r-15"
						@change="selectedProvince">
						<el-option v-for="item in provinceOptions" :key="item.value" :label="item.Province"
							:value="item.Province" />
					</el-select>
					<el-select v-model="invoiceInfoForm.city" filterable placeholder="市"
						:disabled="!invoiceInfoForm.province" class="u-m-r-15" @change="selectedCity"
						@clear="clearSelectedCity">
						<el-option v-for="item in cityOptions" :key="item.value" :label="item.City"
							:value="item.City" />
					</el-select>
					<el-select v-model="invoiceInfoForm.area" filterable placeholder="区"
						:disabled="!invoiceInfoForm.province || !invoiceInfoForm.city" class="u-m-r-15"
						@change="selectedArea" @clear="clearSelectedArea">
						<el-option v-for="item in areaOptions" :key="item.value" :label="item.Area"
							:value="item.Area" />
					</el-select>
					<el-select v-model="invoiceInfoForm.street" filterable placeholder="镇/街"
						:disabled="!invoiceInfoForm.province || !invoiceInfoForm.city || !invoiceInfoForm.area"
						@clear="clearSelectedStreet">
						<el-option v-for="item in streetOptions" :key="item.value" :label="item.Street"
							:value="item.Street" />
					</el-select>
				</el-form-item>
				<el-form-item label="详细地址：" prop="detailAddr" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.detailAddr"></el-input>
				</el-form-item>
			</div> -->
			<!-- <el-form-item label="收票手机号：" prop="deliveryPhone" class="u-m-b-15">
				<el-input type="text" v-model="invoiceInfoForm.deliveryPhone"></el-input>
			</el-form-item> -->
			<el-form-item label="是否默认抬头：" prop="isDefaultTitle" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.isDefaultTitle">
					<el-radio :label="0">否</el-radio>
					<el-radio :label="1">是</el-radio>
				</el-radio-group>
			</el-form-item>
			<div v-if="invoiceInfoForm.invoiceType==2"
				style="margin-left:10px;display:inline-flex;align-items: center;">
				<el-checkbox v-model="invoiceInfoForm.isAgree" size="large"></el-checkbox>
				<text class="agree-text">我已阅读并同意 <a @click="showVatInvoice" style="color:#078DDA;">《增值税专用发票抬头确认书》</a></text>
			</div>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="large" type="primary" @click="saveInfo(ruleFormRef)">提交申请</el-button>
			</span>
		</template>
		<vat-invoice ref="vatInvoiceRef"></vat-invoice>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import {
		userStore
	} from '@/store/user';
	import {
		memberInvoiceTitleSave,
		getProvinceList,
		getDistrictCityList,
		getDistrictAreaList,
		getDistrictStreetList
	} from '@/api/user';
	import {
		ElMessage
	} from 'element-plus';
	import vatInvoice from './vatInvoice.vue'
	const ruleFormRef = ref();
	const invoiceInfoForm = reactive({
		id: '',
		mode: 'add',
		invoiceType: 1,
		titleType: 1,
		invoiceTitle: '',
		invoiceMode: 1,
		email: '',
		taxNum: '',
		bankName: '',
		bankAccount: '',
		address: '',
		phone: '',
		deliveryContact: '',
		deliveryAddress: '',
		deliveryPhone: '',
		province: '',
		city: '',
		area: '',
		street: '',
		detailAddr: '',
		isDefaultTitle: 0
	});
	//收票地址
	const validateAddress = (rule, value, callback) => {
		if (invoiceInfoForm.province == '' || invoiceInfoForm.city == '' || invoiceInfoForm.area == '' ||
			invoiceInfoForm.street == '' || invoiceInfoForm.street == '') {
			callback(new Error("请选择地址"));
		} else {
			callback();
		}
	};

	//表单验证
	const rules = reactive({
		invoiceTitle: [{
			required: true,
			message: '发票抬头不能为空',
			trigger: 'blur'
		}],
		taxNum: [{
				required: true,
				message: '税号不能为空',
				trigger: 'blur'
			},
			{
				pattern: /^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{17}$|^[a-zA-Z0-9]{18}$|^[a-zA-Z0-9]{20}$/,
				message: '请输入正确的单位税号',
				trigger: 'blur'
			}
		],
		email: [{
				required: true,
				message: '邮箱不能为空',
				trigger: 'blur'
			},{
			pattern: /^[A-Za-z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
			message: '请输入合法的邮箱',
			trigger: 'blur'
		}
		]
		// address: [{
		// 	required: true,
		// 	message: '注册地址不能为空',
		// 	trigger: 'blur'
		// }],
		// phone: [{
		// 	required: true,
		// 	message: '注册号码不能为空',
		// 	trigger: 'blur'
		// }, {
		// 	pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
		// 	message: '请输入正确的号码格式',
		// 	trigger: 'blur'
		// }],
		// bankName: [{
		// 	required: true,
		// 	message: '开户银行不能为空',
		// 	trigger: 'blur'
		// }],
		// bankAccount: [{
		// 	required: true,
		// 	message: '银行账号不能为空',
		// 	trigger: 'blur'
		// }, {
		// 	pattern: /^([1-9]{1})(\d{11}|\d{12}|\d{13}|\d{14}|\d{15}|\d{16}|\d{17}|\d{18}|\d{19}|\d{20}|\d{21})$/,
		// 	message: '请输入正确的银行账号',
		// 	trigger: 'blur'
		// }],
		// deliveryContact: [{
		// 	required: true,
		// 	message: '收票人姓名不能为空',
		// 	trigger: 'blur'
		// }],
		// receiveAddress: [{
		// 	required: true,
		// 	validator: validateAddress,
		// 	message: '请选择地址',
		// 	trigger: 'change'
		// }],
		// detailAddr: [{
		// 	required: true,
		// 	message: '详细地址不能为空',
		// 	trigger: 'blur'
		// }],
		// deliveryPhone: [{
		// 	required: true,
		// 	message: '收票手机号不能为空',
		// 	trigger: 'blur'
		// }, {
		// 	pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
		// 	message: '手机号格式不正确',
		// 	trigger: 'blur'
		// }],
	});


	// 行政区
	// const provinceOptions = ref([]);
	// const cityOptions = ref([]);
	// const areaOptions = ref([]);
	// const streetOptions = ref([]);
	// // 获取省列表
	// const getProvince = () => {
	// 	return getProvinceList({}).then(res => {
	// 		provinceOptions.value = res.data.records;
	// 	})
	// }
	// //选择省份
	// const selectedProvince = (province) => {
	// 	clearSelectedCity()
	// 	invoiceInfoForm.province = province;
	// 	getCity(province)
	// }
	// // 根据省份获取城市列表
	// const getCity = () => {
	// 	return getDistrictCityList({
	// 		province: invoiceInfoForm.province
	// 	}).then(res => {
	// 		cityOptions.value = res.data.records;
	// 	})

	// }
	// //选择城市
	// const selectedCity = (city) => {
	// 	clearSelectedArea()
	// 	invoiceInfoForm.city = city;
	// 	getArea(city)
	// }
	// //根据城市获取区列表
	// const getArea = () => {
	// 	let param = {
	// 		province: invoiceInfoForm.province,
	// 		city: invoiceInfoForm.city
	// 	}
	// 	return getDistrictAreaList(param).then(res => {
	// 		areaOptions.value = res.data.records;
	// 	})
	// }
	// //选择区
	// const selectedArea = (area) => {
	// 	clearSelectedStreet()
	// 	invoiceInfoForm.area = area;
	// 	getStreet(area)
	// }
	// //根据区县获取街道列表
	// const getStreet = () => {
	// 	let param = {
	// 		province: invoiceInfoForm.province,
	// 		city: invoiceInfoForm.city,
	// 		area: invoiceInfoForm.area
	// 	}
	// 	return getDistrictStreetList(param).then(res => {
	// 		streetOptions.value = res.data.records;
	// 	})
	// }

	// // 切换省时，清空原先的市和区和街
	// const clearSelectedCity = () => {
	// 	invoiceInfoForm.city = ''
	// 	invoiceInfoForm.area = ''
	// 	invoiceInfoForm.street = ''
	// }

	// // 切换市时，清空原先的区和街
	// const clearSelectedArea = () => {
	// 	invoiceInfoForm.area = ''
	// 	invoiceInfoForm.street = ''
	// }

	// // 切换区时，清空原先的街
	// const clearSelectedStreet = () => {
	// 	invoiceInfoForm.street = ''
	// }
	//增值税专用发票抬头确认书
	const vatInvoiceRef = ref(false);
	const showVatInvoice = () => {
		vatInvoiceRef.value.onChangeVatInvoiceVisible()
	};
	//保存信息
	const saveInfo = (form) => {
		if (!form) return
		if (invoiceInfoForm.invoiceType == 2 && !invoiceInfoForm.isAgree) {
			ElMessage({
				message: '请先阅读并勾选《增值税专用发票抬头确认书》',
				type: 'error',
				duration: 1000
			})
			return
		}
		form.validate((valid, fields) => {
			if (valid) {
				let res = {
					mode: invoiceInfoForm.mode,
					invoiceType: invoiceInfoForm.invoiceType,
					titleType: invoiceInfoForm.titleType,
					invoiceTitle: invoiceInfoForm.invoiceTitle,
					invoiceMode: invoiceInfoForm.invoiceMode,
					email: invoiceInfoForm.email,
					taxNum: invoiceInfoForm.taxNum,
					// bankName: invoiceInfoForm.bankName,
					// bankAccount: invoiceInfoForm.bankAccount,
					// address: invoiceInfoForm.address,
					// phone: invoiceInfoForm.phone,
					// deliveryContact: invoiceInfoForm.deliveryContact,
					// deliveryPhone: invoiceInfoForm.deliveryPhone,
					// province: invoiceInfoForm.province,
					// city: invoiceInfoForm.city,
					// area: invoiceInfoForm.area,
					// street: invoiceInfoForm.street,
					// detailAddr: invoiceInfoForm.detailAddr,
					// deliveryAddress: invoiceInfoForm.province + invoiceInfoForm.city + invoiceInfoForm
					// 	.area + invoiceInfoForm.street + invoiceInfoForm.detailAddr,
					// isDefaultTitle: invoiceInfoForm.isDefaultTitle
				}
				//console.log('2222', res)
				memberInvoiceTitleSave(res).then(() => {
					ElMessage({
						message: '提交成功',
						type: 'success',
						duration: 1000
					})
					dialogVisible.value = false;
				})
			}
		})
	};

	//打开弹窗时
	const dialogVisible = ref(false);
	const onChangeVisible = (infoForm) => {
		invoiceInfoForm.invoiceType = infoForm.invoiceType
		invoiceInfoForm.titleType = infoForm.titleType
		invoiceInfoForm.invoiceMode = infoForm.invoiceMode
		dialogVisible.value = true;
		invoiceInfoForm.invoiceTitle = userStore().memberInfo.memberName;
	};

	defineExpose({
		onChangeVisible
	});

	//关闭弹窗重置表单
	const closeDialog = () => {
		dialogVisible.value = false;
		ruleFormRef.value.resetFields();
	}
</script>

<style lang="scss" scoped>

</style>