<template>
	<div class="w">
		<div class="tab-wrap">我要分享</div>
    <el-form :model="shareForm" ref="shareFormRef" label-position="top">
      <!-- 内容 -->
			<el-form-item label="请填写分享内容：" prop="shareContent" :rules="[{ required: true, message: '请输入您的想法' }]">
				<el-input v-model="shareForm.shareContent" type="textarea" :autosize="{ minRows: 5 }" clearable
					placeholder="请填写你要分享的内容..." />
			</el-form-item>

      <!-- 上传图片或视频 -->
			<el-form-item label="上传图片或视频">
				<div class="img-list" v-if="fileList.length > 0">
					<div class="img-item" v-for="(item, index) in fileList" :key="index">
            <video style="object-fit: cover" :src="item.url" controls v-if="item.name.endsWith('.mp4')"></video>
						<img :src="item.url" v-else>
						<div class="icon-item" @click.stop="handleRemove(index)">
							<el-icon :size="20"><CircleCloseFilled color="#fa3534" /></el-icon>
						</div>
					</div>
				</div>
				<el-upload v-if="fileList.length <= 9" action="https://up-z2.qiniup.com/" :data="uploadParams" list-type="picture-card" :format="['jpg','jpeg','png','mp4']" 
          :show-file-list="false" :limit="9" :multiple="true" :on-success="onSuccessImg" :before-upload="beforeUpload">
					<el-icon><Plus /></el-icon>
				</el-upload>
			</el-form-item>

      <!-- 关联产品 -->
      <div style="margin-bottom: 24px;" class="u-flex-row-content">
        <div>请在下列关联产品：</div>
        <el-input v-model="keyword" style="width: 320px;" placeholder="可搜索产品关键词" class="u-m-r-15" :suffix-icon="Search" @input="onSearch"></el-input>
      </div>
      <div class="share-pro" :class="{'share-pro--active' : item.checked}" v-for="(item, index) in productList" :key="index" @click="onSelectPro(item)">
        <img :src="handlerImgUrl(item.PicPath)">
        <div>
          <div class="share-pro__title">{{ item.Brand + item.ProductName }}</div>
          <div class="share-pro__text">好评率：{{ item.EvaluateRate }}%</div>
        </div>
      </div>

      <el-form-item>
        <div class="share-btn">
          <el-button style="width: 160px; height: 44px;" type="primary" @click="onShare(shareFormRef)">我要分享</el-button>
        </div>
      </el-form-item>
		</el-form>
	</div>

</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { Plus, CircleCloseFilled, Search } from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	import { handlerImgUrl } from "@/common/index";
	import { getQiNiuYunToken } from '@/api/user'
  import { webShareProductSave, webShareRelatedProductList } from '@/api/flower'

  const router = useRouter()

  onMounted(() => {
    getProductList()
  })

  /**
   * 取关联产品列表
   * @param {无}
   */
  const productList = ref([])
  const copyProductList = ref([])
  const getProductList = async () => {
    const { data: { records } } = await webShareRelatedProductList()
    productList.value = records.map(item => {
      return { ...item, checked: false }
    })
    copyProductList.value = productList.value
  }

  // 表单信息
  const shareFormRef = ref()
  const shareForm = reactive({
    shareContent: '',
    sharePicPath: '',
    shareVideo: '',
    relatedProductId: ''
  })

  // 上传图片
	const uploadParams = reactive({ token: '', key: '' })
	const beforeUpload = async (request) => {
    // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
    // 上传之前设置上传参数,
    const suffix = request.name.endsWith('.mp4') ? ".mp4" : ".png"
    let filename = Date.now() + Math.random().toString(36).slice(2) + suffix;
		const { data: { token } } = await getQiNiuYunToken()
		uploadParams.token = token
		uploadParams.key = filename
  }
	// 删除图片
	const handleRemove = (index) => {
		fileList.splice(index, 1)
	}
	const fileList = reactive([])
	const onSuccessImg = (uploadFile) => {
    const imgObj = {
      name: uploadFile.key, 
      url: handlerImgUrl(uploadFile.key)
    }
    uploadFile.key.endsWith('.mp4') ? fileList.unshift(imgObj) : fileList.push(imgObj)
	}

  /**
   * 搜索产品
   * @param {*} event 
   */
  const keyword = ref('')
  const onSearch = (event) => {
    productList.value = copyProductList.value.filter(item => item.ProductName.indexOf(event) > -1)
  }

  /**
   * 选中产品
   * @param {object} event 
   */
  const onSelectPro = (event) => {
    event.checked = !event.checked
    shareForm.relatedProductId = productList.value.filter(item => item.checked).map(item => item.Id).join(',')
  }

  /**
   * 我要分享
   * @param {object} formEl
   */
  const onShare = (formEl) => {
    if (!formEl) return
		formEl.validate(async (valid) => {
			if (valid) {
				shareForm.shareVideo = fileList.filter(item => item.name.endsWith('.mp4')).map(item => item.name).join(',')
        shareForm.sharePicPath = fileList.filter(item => item.name.endsWith('.png')).map(item => item.name).join(',')
        const { code } = await webShareProductSave(shareForm)
        if (code == 0) {
          ElMessage({ type: 'success', message: '分享成功' })
          router.go(-1)
        }
			} else {
				console.log('error submit!')
				return false
			}
		})
  }

</script>

<style lang="scss" scoped>
	.tab-wrap {
		margin: 20px 0px;
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		border-bottom: 1px solid #FE5BA3;
    color: #FE5BA3;
	}

  .img-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.img-item {
			position: relative;
			width: 145px;
			height: 145px;
			border-radius: 10px;
			margin-right: 10px;
			border: 1px solid #cdd0d6;

			img {
				width: 100%;
				height: 100%;
			}

      video {
        width: 100%;
				height: 100%;
      }
		}

		.icon-item {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer;
		}
	}

  .share-pro {
    box-sizing: border-box;
    width: 390px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 13px;
    background: #FAFAFA;
    border: 1px solid #FAFAFA;
    font-family: Source Han Sans CN;
    cursor: pointer;
    border-radius: 8px;

    &--active {
      border-color: #FE5BA3;
    }

    img {
      width: 42px;
      height: 46px;
      border-radius: 2px;
      margin-right: 15px;
    }

    &__title {
      font-size: 17px;
      color: #333333;
    }

    &__text {
      font-size: 15px;
      color: #747474;
    }
  }

  .share-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 78px;
  }
</style>
