<template>
	<!-- 列表 -->
	<div class="flower-list" :style="{ height: maxHeight + 'px' }" id="flower-list">
		<div class="list-box" v-for="(item, index) in props.list" :key="index" @click="flowerDetails(item)">
			<div class="top-box">
				<div class="name-wrap">
					<el-avatar :size="60" :src="handlerImgUrl(item.HeadPicPath)"></el-avatar>
					<div class="name-box">
						<div class="name">{{ item.Uploader }}</div>
						<div class="time">发表时间：{{ item.CreateTime }}</div>
					</div>
				</div>
				<div class="like-box" @click.stop="onLike(item)">
					<i class="iconfont icon-xihuan-shoucang-aixin" :class="item.isLike == 1 ? 'active' : ''"></i>
					<span v-if="item.LikeCount > 0">{{ item.LikeCount }}</span>
				</div>
			</div>
			<div class="u-m-t-15">
				<div class="info">{{ item.ShareContent }}</div>
				<video class="info-img" style="object-fit: cover" :src="handlerImgUrl(item.ShareVideo)" controls v-if="item.ShareVideo"></video>
				<span v-if="item.SharePicPath">
					<el-image class="info-img" :src="handlerImgUrl(img)" fit="cover" v-for="img in item.SharePicPath.split(',')" :key="img"></el-image>
				</span>
			</div>
		</div>
	</div>

	<!-- 详情 -->
	<el-dialog v-model="flowerDetailsVisible" title="查看详情">
		<div class="details-box">
			<div class="img-box">
				<el-carousel :interval="5000" height="450px" arrow="always">
					<el-carousel-item v-if="details.ShareVideo">
						<video height="450" width="375" style="object-fit: cover" :src="handlerImgUrl(details.ShareVideo)" controls></video>
					</el-carousel-item>
					<div v-if="details.SharePicPath">
						<el-carousel-item v-for="(item, index) in details.SharePicPath.split(',')" :key="index" name="index">
							<el-image :src="handlerImgUrl(item)"></el-image>
						</el-carousel-item>
					</div>
				</el-carousel>
			</div>
			<div style="margin-left: 10px">
				<div class="top-box">
					<div class="name-wrap">
						<el-avatar :size="60" :src="handlerImgUrl(details.HeadPicPath)"></el-avatar>
						<div class="name-box">
							<div class="name">{{ details.Uploader }}</div>
							<div class="time">发表时间：{{ details.CreateTime }}</div>
						</div>
					</div>
					<div class="like-box" @click="onLike(details)">
						<i class="iconfont icon-xihuan-shoucang-aixin" :class="details.isLike == 1 ? 'active' : ''"></i>
						<span v-if="details.LikeCount > 0">{{ details.LikeCount }}</span>
					</div>
				</div>
				<div class="product" v-if="details.RelatedProductList && details.RelatedProductList.length > 0">
					<div class="title">关联的产品</div>
					<div class="proList" v-for="(item, index) in details.RelatedProductList" :key="index">
						<img class="pro-img" :src="handlerImgUrl(item.picPath)">
						<div class="pro-info">
							<div class="pro-name">{{ item.productName }}</div>
							<div class="positive-rate">好评率：{{ item.evaluateRate }}%</div>
							<div class="positive-rate">商品价格：{{ item.salePrice }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>

</template>

<script setup>
	import { ref, reactive, onMounted, onUnmounted, watchEffect, watch } from 'vue';
	import { handlerImgUrl } from '@/common/index';
	import { ElMessage } from 'element-plus'
	import { webLikeCountSave } from '@/api/flower'

	const props = defineProps({
		list: {
			type: Array,
			default: []
		}
	})
	
	watch(() => props.list, (newVal) => {
		setTimeout(() => {
			waterFall('#flower-list', '.list-box')
		}, 10)
	})

	/**
	 * @param { string } wrapIdName 容器id(或class)名称
	 * @param { string } contentIdName 容器中内容项id(或class)名称
	 * @param { number } column 容器中内容展示列数 默认为3
	 * @param { number } columnGap 容器中列间隔距离 默认为50
	 * @param { number } rowGap 容器中行间隔距离 默认为45
	 */
	const maxHeight = ref(0)
	const waterFall = (wrapIdName, contentIdName, columns = 3, columnGap = 50, rowGap = 45) => {
	  // 获得内容可用宽度（去除滚动条宽度）
	  const wrapContentWidth = document.querySelector(wrapIdName).offsetWidth - 8;

	  // 间隔空白区域
	  const whiteArea = (columns - 1) * columnGap;

	  // 得到每列宽度(也即每项内容宽度)
	  const contentWidth = parseInt((wrapContentWidth - whiteArea) / columns); 

	  // 得到内容项集合
	  const contentList = document.querySelectorAll(contentIdName);

	   // 成行内容项高度集合
	  const lineConentHeightList = []

	  for (let i = 0; i < contentList.length; i++) {
	    // 获取内容项高度
	    const height = contentList[i].clientHeight;
	
	    if (i < columns) {
	      // 第一行按序布局
	      contentList[i].style.top = 0;
	      contentList[i].style.left = contentWidth * i + columnGap * i + 'px';
		
	      // 将行高push到数组
	      lineConentHeightList.push(height)
	    } else {
	      // 其他行
	      // 获取数组最小的高度 和 对应索引			
				let minHeight = Math.min(...lineConentHeightList)
	      let index = lineConentHeightList.findIndex(listH => listH === minHeight)
		
	      contentList[i].style.top = (minHeight + rowGap) + 'px';
	      contentList[i].style.left = (contentWidth + columnGap) * index + 'px';
		
	      // 修改最小列的高度 最小列的高度 = 当前自己的高度 + 拼接过来的高度 + 行间距
	      lineConentHeightList[index] += height + rowGap
	    }
	  }
		maxHeight.value = lineConentHeightList.length > 0 ? Math.max(...lineConentHeightList) : 0
	}

	/**
	 * 点赞或取消点赞
	 * @param {*} event 
	 */
	const onLike = async (event) => {
		const flag = event.isLike == 0
		const params = {
			Id: event.Id,
			isLike: flag ? 1 : 0
		}
		await webLikeCountSave(params)
		ElMessage.success(flag ? '已点赞' : '已取消')
		if (flag) {
			event.isLike = 1
			event.LikeCount++
		} else {
			event.isLike = 0
			event.LikeCount--
		}
	}

	/**
	 * 弹窗详情
	 * @param {object} event
	 */
	const flowerDetailsVisible = ref(false);
	const details = ref({});
	const flowerDetails = (event) => {
		details.value = event
		flowerDetailsVisible.value = true;
	}

</script>

<style lang="scss" scoped>
	.flower-list {
		position: relative;
	}

	.list-box {
		position: absolute;
		width: 420px;
		background: #FFFFFF;
		border-radius: 16px;
		box-shadow: 0px 0px 10px #ececec;
		padding: 10px;
		// break-inside: avoids;
		word-wrap: break-word;

		.info {
			font-size: 17px;
			font-weight: 400;
			color: #1D1D1D;
		}

		.info-img {
			display: inline-block;
			width: 125px;
			height: 85px;
			margin-right: 15px;
			margin-top: 15px;
			border-radius: 5px;
		}
	}
	.top-box {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	
		.name-wrap {
			display: flex;
			align-items: center;
	
			.name-box {
				margin-left: 10px;
	
				.name {
					font-size: 17px;
					font-weight: 500;
					color: #242424;
					font-weight: bold;
					margin-bottom: 10px;
				}
	
				.time {
					font-size: 14px;
					font-weight: 400;
					color: #585858;
				}
			}
		}
	
		.like-box {
			display: inline-flex;
			align-items: center;
			font-size: 15px;
			font-weight: 400;
			color: #727272;
	
			.icon-xihuan-shoucang-aixin {
				font-size: 30px;
				color: #727272;
			}
	
			.active {
				color: #FE5BA3;
			}
		}
	
	}

	.details-box {
		display: flex;

		.img-box {
			width: 375px;
			margin-right:20px;
		}
		.product{
			.title{
				font-size: 18px;
				font-weight: bold;
				color: #515151;
				line-height: 45px;
				margin-top:15px;
			}
			.proList{
				box-sizing: border-box;
				width: 388px;
				display: flex;
				align-items: center;
				margin-top: 15px;
				background: #FAFAFA;
				border-radius: 8px;
				padding: 13px;

				.pro-img {
					width:45px;
					height:45px;
					margin-right:15px;
				}
				.pro-info{
					.pro-name{
						font-size: 17px;
						font-weight: 400;
						color: #333333;
						line-height: 34px;
					}
					.positive-rate{
						font-size: 15px;
						font-weight: 400;
						color: #747474;
						line-height: 30px;
					}
				}
			}
		}
	}
</style>