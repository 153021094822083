import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
//引入elementplus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// 将element-plus默认的英文，改为中文
import locale from 'element-plus/lib/locale/lang/zh-cn';
// 修改elementplus主题颜色
import '@/assets/style/variarbles.scss';
//引入页面自适应
import '@/utils/lib-flexible';
//引入createPinia
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist';

//引入自定义图标
import '@/assets/icon/iconfont.css';

import { VueMasonryPlugin } from 'vue-masonry';
import { init as initApm } from '@elastic/apm-rum';

/**
 * 构建需要切换为 environment: 'prod'
 * @type {ApmBase}
 */
var apm = initApm({
  serviceName: 'flower-web',
  serverUrl: 'https://apm.kainonly.com:8443',
  serviceVersion: '',
  environment: process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
});

createApp(App)
  .use(router)
  .use(ElementPlus, { locale })
  .use(createPinia().use(piniaPluginPersist))
  .use(VueMasonryPlugin)
  .mount('#app');

//处理element-plus 报错 ResizeObserver loop limit exceeded
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
