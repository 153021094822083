/*
 * @Author: patio
 * @Date: 2023-08-05 09:47:54
 * @FilePath: \backstaged:\鲜花网\Web\src\common\index.js
 * @LastEditors: error: git config user.name & please set dead value or install git
 * @LastEditTime: 2023-04-05 10:03:30
 */

import { ElLoading } from "element-plus";
import moment from "moment";

/**
 * 校验图片
 * @param {String} url
 */
export function handlerImgUrl(url) {
  if (!url) {
    return "";
  }
  if (/^(http|https|blob|\/\/)/.test(url)) {
    return url;
  } else {
    return 'http://img.brightenflower.cn/' + url;
  }
}

/**
 * 防抖
 * @param {Function} func 传入需要防抖的函数
 * @param {Number} delay 等待时间
 * @returns 
 */
export function debounce(func, delay = 200) {
  // 缓存一个定时器
  let timer = 0
  // 这里返回的函数是每次用户实际调用的防抖函数 
  return function () {
    // 如果已经设定过定时器了就清空上一次的定时器
    if (timer) clearTimeout(timer)
    // 开始一个新的定时器，延迟执行用户传入的方法
    timer = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}

/**
 * 节流
 * @param {Function} func 传入函数
 * @param {Number} delay 等待时间
 * @returns 
 */
export function throttle(func, delay = 200) {
  // 缓存一个定时器
  let last = 0
  // 这里返回的函数是每次用户实际调用的防抖函数 
  return function (...args) {
    let now = new Date().getTime()
    // 开始一个新的定时器，延迟执行用户传入的方法
    if (now - last > delay) {
      last = now
      func.apply(this, args)
    }
  }
}

/**
 * 为函数参数设置默认值(组合)
 */
export function setDefaults(defaults) {
  return function (params = {}) {
    return { ...defaults, ...params }
  }
}

/**
 * 为函数参数设置默认值（高阶函数）
 */
export function withDefaultsParams(defaults) {
  return function (fn) {
    return function (params = {}, ...args) {
      return fn({ ...defaults, ...params }, ...args)
    }
  }
}

/**
 * 获取晚场的销售时间
 */
export function getAfterNoonSaleDate() {
  let current = moment();
  let endTime = moment().hours(17).minutes(0).seconds(0)
  console.log("当前时间", current.format("YYYY-MM-DD HH:mm:ss"))
  if (current.isBefore(endTime)) {
    return current.format("YYYY-MM-DD")
  } else {
    return current.add(1, "day").format("YYYY-MM-DD")
  }
}

export async function startLoading(cb, options = {}) {
  const loading = ElLoading.service({
    lock: true,
    text: '正在加载中...',
    background: 'rgba(0, 0, 0, 0.7)',
    ...options,
  })
  try {
    cb && await cb();
  } finally {
    loading.close();
  }
}










