<template>
	<div class="footer" :class="{'u-m-b-15': isMb}">
		<div>版权所有 © 广州缤纷园艺有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备05082648号</a></div>
		<div>建议使用Chrome内核的浏览本网站</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
import { useRoute } from 'vue-router';
	const route = useRoute()

	const isMb = computed(() => {
		return route.path != '/index/orderProduct'
	})
</script>

<style>
.footer {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0px;
  left: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #717171;
}

@media screen and (max-height: 768px) {
  .footer {
    display: none; /* 小屏设备上隐藏 */
  }
}

</style>
