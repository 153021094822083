<template>
  <div style="overflow-y: auto; height: 90vh; scrollbar-width: none">
    <div
      style="cursor: pointer; margin-bottom: 10px; font-size: 0.09rem"
      class="u-flex-row-content u-m-t-5"
      @click="address.onAddressVisible(userInfo.memberDetailInfo.addressId)"
    >
      <div class="address">收货地址：{{ userInfo.memberDetailInfo.shippingAddress }}</div>
      <el-icon :size="20">
        <ArrowRight />
      </el-icon>
    </div>
    <!-- 已购货单 竞购订单 预购订单 -->
    <orderList v-model:active-name="state.activeName"></orderList>

    <!-- 收货地址 -->
    <address-modal ref="address" @updateMember="onUpdateMemberInfo"></address-modal>
  </div>
</template>

<script setup>
import { reactive, watch, ref } from 'vue';
import orderList from './components/order-list/index.vue';
import { SALE_TYPE } from '@/common/const';
import { userStore } from '@/store/user';
import AddressModal from '@/components/address-modal/index.vue';
import { Search, ArrowRight } from '@element-plus/icons-vue';

const props = defineProps({
  sale_type: {
    type: Number,
    default: ''
  }
});

const state = reactive({
  activeName: 'purchaseOrder'
});

const userInfo = userStore();

const address = ref();

watch(
  () => props.sale_type,
  (newValue, oldValue) => {
    console.log('监听订单列表变化', newValue);
    if (props.sale_type == SALE_TYPE.TODAY) {
      state.activeName = 'purchaseOrder';
    } else if (props.sale_type == SALE_TYPE.BID) {
      state.activeName = 'biddingOrder';
    } else if (
      props.sale_type == SALE_TYPE.TOMMOROW ||
      props.sale_type == SALE_TYPE.AFTER_ONE_TOMMOROW ||
      props.sale_type == SALE_TYPE.AFTER_TWO_TOMMOROW ||
      props.sale_type == SALE_TYPE.PRE_SALE
    ) {
      state.activeName = 'bookingOrder';
    } else if (props.sale_type == SALE_TYPE.AFTERNOON) {
      state.activeName = 'presaleOrder';
    } else {
      state.activeName = '';
    }
    console.log('监听订单列表变化', state.activeName);
  }
);

function onUpdateMemberInfo(event) {
  userInfo.getMemberInfo();
  let oldActiveName = state.activeName;
  state.activeName = '';
  setTimeout(() => {
    state.activeName = oldActiveName;
  }, 200);
}
</script>
