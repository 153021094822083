<template>
  <el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="会员充值">
    <el-tabs type="border-card" :stretch="true" v-model="activeName">
      <el-tab-pane label="在线充值" name="online">
        <el-form ref="onlineRechargeRef" :model="onlineRechargeForm" class="form">
          <el-form-item label="充值金额：" prop="rechargeAmount">
            <el-space>
              <el-radio-group size="large" v-model="onlineRechargeForm.rechargeAmount" fill="#FC4D9A"
                text-color="#FFFFFF">
                <el-radio-button :label="100">100元</el-radio-button>
                <el-radio-button :label="500">500元</el-radio-button>
                <el-radio-button :label="1000">1000元</el-radio-button>
                <el-radio-button :label="2000">2000元</el-radio-button>
                <el-radio-button :label="0">输入其他金额</el-radio-button>
              </el-radio-group>
              <el-input-number style="width: 180px" v-model="onlineRechargeForm.rechargeOtherAmount"
                :disabled="onlineRechargeForm.rechargeAmount !== 0" :precision="2" :min="0" />
            </el-space>
          </el-form-item>
          <el-form-item label="支付金额：" prop="paymentAmount">
            <div style="font-size: 18px; font-weight: bold">
              {{
                onlineRechargeForm.rechargeAmount !== 0
                  ? onlineRechargeForm.rechargeAmount
                  : onlineRechargeForm.rechargeOtherAmount
              }}
              元
            </div>
          </el-form-item>
          <el-form-item label="支持平台：">
            <el-space size="large">
              <img class="pay-logo" src="@/assets/image/wechat-pay.svg" alt="微信支付" />
              <img class="pay-logo" src="@/assets/image/alipay.svg" alt="支付宝" />
              <img class="pay-logo" src="@/assets/image/union-pay.svg" alt="云闪付" />
            </el-space>
          </el-form-item>
          <div class="tips">
            <div>在线充值说明： </div>
            <div>1、在线扫码充值，立即到会员账户。 </div>
            <div>2、微信与支付宝扫码充值减免手续费，如国家政策调整，鲜花网会提前通告收费标准。</div>
            <div>3、如需线下汇款或手动录款，请点击右边“转账充值”，此方法汇款后需人工录入，款到账较慢，慎用！</div>
          </div>
          <div class="btn">
            <el-button type="primary" @click="onRecharge">立即充值</el-button>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="转账充值" name="transfer">
        <div class="u-flex-row">
          <div class="transfer-box">
            <el-image class="bank-img" :src="require('@/assets/image/bankImg.png')"></el-image>
            <div class="transfer-info">
              <div class="tips">亦可汇入公司账号，并备注您的五位会员卡号。</div>
              <div>收款人：广州缤纷园艺有限公司</div>
              <div>开户行：农行广东省佛山市南海区里水镇和顺支行</div>
              <div>账户类型：支票户</div>
              <div>账号：44522001040013341</div>
            </div>
          </div>
          <div class="transfer-box">
            <el-image class="bank-img" :src="require('@/assets/image/alipay.png')"></el-image>
            <div class="transfer-info">
              <div class="tips">亦可汇入支付宝账户，并备注您的五位会员卡号。</div>
              <el-image :src="require('@/assets/image/alipay-code.png')"></el-image>
              <div style="color: #e76205">finance@brighten.cn</div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 支付窗口 -->
    <pay ref="payRef" @update="dialogVisible = false"></pay>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { recharge } from '@/api/productSale';
import pay from '../member-pay-modal/index.vue';
import { userStore } from '@/store/user';
import router from '@/router';
import { ElMessageBox } from 'element-plus';

const activeName = ref('online');
const onlineRechargeForm = reactive({
  rechargeAmount: 500,
  rechargeOtherAmount: 0
});

const userInfo = userStore();
const payRef = ref();
const onRecharge = async () => {
  if (userInfo.addressList.length === 0 || userInfo.addressList.filter(e => e.AddrState === 1).length === 0) {
    ElMessageBox.confirm('您还没有收货地址，请先添加收货地址！', '温馨提示', {
      type: 'warning',
      cancelButtonText: '否',
      confirmButtonText: '是'
    }).then(() => {
      //跳转到地址管理页面
      router.push('/index/personalCenter/shipAddress');
    });
    return;
  }

  const orderAmount = Number(
    onlineRechargeForm.rechargeAmount !== 0 ? onlineRechargeForm.rechargeAmount : onlineRechargeForm.rechargeOtherAmount
  );
  const { data } = await recharge({ orderAmount: orderAmount });
  payRef.value.onChangeVisible({
    ...data,
    orderAmount
  });
};

const dialogVisible = ref(false);
const onChangeVisible = () => {
  onlineRechargeForm.rechargeOtherAmount = 0;
  dialogVisible.value = true;
};

defineExpose({
  onChangeVisible
});
</script>

<style lang="scss" scoped>
.pay-logo {
  height: 36px;
}

// tabs选中效果
.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  color: var(--el-bg-color-overlay) !important;
  background-color: var(--el-color-primary) !important;
  border-right-color: var(--el-color-primary) !important;
  border-left-color: var(--el-color-primary) !important;
}

.form {
  .el-form-item {
    margin: 30px 0px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 400;
      color: #3a3a3a;
    }
  }

  .tips {
    font-size: 16px;
    font-weight: 400;
    color: #ff1a1a;
    line-height: 28px;
  }

  .btn {
    text-align: center;
    padding: 30px 0px;

    .el-button {
      width: 380px;
      height: 52px;
      line-height: 52px;
      background: #fc4d9a;
      border-radius: 6px;
    }
  }

  .el-radio-button__inner {
    border-left: 1px solid #ededed;
    border-radius: 2px !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }
}

.radio-button-box {
  display: flex;
  align-items: center;
}

// 单选按钮样式
.my-radio-button {
  margin-right: 15px;
}

// 其他金额
.other-amount {
  display: flex;
  align-items: flex-end;
  margin-left: 15px;

  .tips {
    margin-left: 30px;
    font-size: 15px;
    font-weight: 400;
    color: #ff7f4d;
  }
}

// 单选按钮图片
.radio-img {
  height: 28px;
}

// 转账充值
.transfer-box {
  text-align: center;
  margin: 30px auto;
  padding: 16px 0;
  width: 420px;
  //height: 418px;
  border: 2px solid #329787;
  border-radius: 3px;

  &:last-child {
    border: 2px solid #eb5f02;

    .bank-img {
      width: 182px;
    }

    .transfer-info {
      text-align: center;
      line-height: 1;
    }
  }

  .transfer-info {
    font-size: 20px;
    font-weight: bold;
    color: #219b84;
    line-height: 44px;
    margin: 15px 14px 0;
    text-align: left;

    .tips {
      font-size: 18px;
      font-weight: 400;
      color: #1d1d1b;
      line-height: 42px;
    }
  }

  .bank-img {
    margin-top: 30px;
    width: 259px;
    height: 57px;
  }
}
</style>
