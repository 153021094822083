<template>
	<el-dialog v-model="evaluateDialogVisible" :close-on-click-modal="false" title="商品评价" draggable width="800px" @close="closeDialog">
		<el-form :model="evaluateForm" ref="evaluateFormRef" label-width="120px">
			<el-form-item label="评价产品:" class="u-m-t-15">
				<span>{{evaluateForm.brand+evaluateForm.productName+evaluateForm.grade}}</span>
			</el-form-item>
			<el-form-item label="评价星级:" class="u-m-t-15" prop="evaluateRate" :rules="[ {required: true,validator:validateRate,trigger: 'change'}]">
				<el-rate v-model="evaluateForm.evaluateRate" ></el-rate>
			</el-form-item>
			<el-form-item label="评价内容:" class="u-m-t-15" prop="evaluateContent">
				<el-input v-model="evaluateForm.evaluateContent" type="textarea" :autosize="{ minRows: 5 }" clearable
					placeholder="请填写您的评价内容，感谢您的支持~" />
			</el-form-item>
			<el-form-item label="评价图片" class="u-m-t-15" prop="evaluateImg">
				<div class="img-list" v-if="evaluateForm.fileList.length > 0">
					<div class="img-item" v-for="(item, index) in evaluateForm.fileList" :key="index"
						@click="handlePictureCardPreview(item.url)">
						<img :src="item.url">
						<div class="icon-item" @click.stop="handleRemove(index)" v-if="evaluateForm.evaluateId==''">
							<el-icon :size="20">
								<CircleCloseFilled color="#fa3534" />
							</el-icon>
						</div>
					</div>
				</div>
				<el-upload v-if="evaluateForm.fileList.length <= 9&&evaluateForm.evaluateId==''" action="https://up-z2.qiniup.com/" :data="uploadParams"
					list-type="picture-card" :format="['jpg','jpeg','png']" :show-file-list="false" :limit="9"
					:multiple="true" :on-success="onSuccessImg" :before-upload="beforeUpload">
					<el-icon>
						<Plus />
					</el-icon>
				</el-upload>
				<el-dialog v-model="dialogVisibleImg" width="630px" title="查看图片">
					<div style="">
						<img :src="dialogImageUrl" alt="Preview Image" style="max-width:600px;"/>
					</div>
				</el-dialog>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="large" type="primary" @click="submitEvaluateForm(evaluateFormRef)" v-if="evaluateForm.evaluateId==''">提交评价</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {reactive,ref} from 'vue';
	import {Plus,CircleCloseFilled} from '@element-plus/icons-vue'
	import {ElMessage} from 'element-plus'
	import {handlerImgUrl} from "@/common/index";
	import {getQiNiuYunToken,orderEvaluateSave,orderEvaluateInfo} from '@/api/user'
	const emit = defineEmits(['update'])
	
	// 获取表单对象
	const evaluateFormRef = ref()
	const evaluateForm = reactive({
		evaluateId:'',
		objectId:'',
		objectType:'',
		brand: '',
		productName: '',
		grade: '',
		evaluateRate:0,
		evaluateContent: '',
		evaluateImg: '',
		fileList:[]
	});
	/**
	 * 上传图片
	 */
	const uploadParams = reactive({
		token: '',
		key: ''
	})
	const beforeUpload = async (request) => {
		// **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
		// 上传之前设置上传参数,
		let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
		const {data: {token}} = await getQiNiuYunToken()
		uploadParams.token = token
		uploadParams.key = filename
	}
	// 删除图片
	const handleRemove = (index) => {
		evaluateForm.fileList.splice(index, 1)
	}
	// 预览图片
	const dialogVisibleImg = ref(false)
	const dialogImageUrl = ref('')
	const handlePictureCardPreview = (event) => {
		dialogImageUrl.value = event
		dialogVisibleImg.value = true
	}
	
	const onSuccessImg = (uploadFile) => {
		evaluateForm.fileList.push({
			name: uploadFile.key,
			url: handlerImgUrl(uploadFile.key)
		})
	}
	
	/**
	 * 获取商品评价信息
	 * @param {无}
	 */
	const getOrderEvaluateInfo=()=>{
		return orderEvaluateInfo({id:evaluateForm.evaluateId}).then(res => {
			let orderEvaluateInfo=res.data.orderEvaluateInfo;
			evaluateForm.id=orderEvaluateInfo.id;
			evaluateForm.evaluateRate=orderEvaluateInfo.evaluateRate;
			evaluateForm.evaluateContent=orderEvaluateInfo.evaluateContent;
			let files=orderEvaluateInfo.evaluateImg.split(",");
			let tmpFilesList = [];
			files.forEach((e)=>{
				if(e){
					tmpFilesList.push({
						url:handlerImgUrl(e)
					})
				}
			});
			evaluateForm.fileList=tmpFilesList;
		})
	}
	
	//表单验证
	const validateRate=(rule,value,callback)=>{
		if(value===''||value==undefined||value==0){
			callback(new Error("请评价星级"));
		}
		callback()
	}
	
	// 表单校验函数
	const submitEvaluateForm = (formEl) => {
		if (!formEl) return
		formEl.validate(async (valid) => {
			if (valid) {
				if (evaluateForm.fileList.length > 0) {
					evaluateForm.evaluateImg = evaluateForm.fileList.map(item => item.name).join(',')
				}
				if(evaluateForm.objectType=='PC加单'||evaluateForm.objectType=='PC现售'||evaluateForm.objectType=='小程序现售'||evaluateForm.objectType=='APP现售'){
					evaluateForm.objectType=1
				}else if(evaluateForm.objectType=='PC竞购'||evaluateForm.objectType=='小程序竞购'||evaluateForm.objectType=='APP竞购'){
					evaluateForm.objectType=2
				}else if(evaluateForm.objectType=='PC预定'||evaluateForm.objectType=='小程序预定'||evaluateForm.objectType=='APP预定'){
					evaluateForm.objectType=3
				}
				let res = {
					objectId:evaluateForm.objectId,
					objectType:evaluateForm.objectType,
					evaluateRate: evaluateForm.evaluateRate,
					evaluateImg: evaluateForm.evaluateImg,
					evaluateContent : evaluateForm.evaluateContent
				}
				await orderEvaluateSave(res).then(() => {
					ElMessage({message: '提交成功',type: 'success',duration:1000})
					evaluateDialogVisible.value = false
					emit('update', true)
				})
			} else {
				console.log('error submit!')
				return false
			}
		})
	}

	/**
	 * 弹窗显示
	 * @param {无}
	 */
	const evaluateDialogVisible = ref(false);
	const onChangeEvaluateVisible = (event) => {
		evaluateForm.objectId=event.Id
		evaluateForm.objectType=event.SaleType
		evaluateForm.brand=event.Brand
		evaluateForm.productName=event.ProductName
		evaluateForm.grade=event.Grade
		evaluateForm.evaluateId=event.EvaluateId
		if(evaluateForm.evaluateId!==''){
			getOrderEvaluateInfo()
		}
		evaluateDialogVisible.value = true;
	};
	defineExpose({
		onChangeEvaluateVisible
	});
	
	//关闭弹窗重置表单
	const closeDialog=()=>{
		evaluateDialogVisible.value = false;
		evaluateFormRef.value.resetFields();
		evaluateForm.fileList=[];
	}
	
</script>

<style lang="scss" scoped>
	.img-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.img-item {
			position: relative;
			width: 145px;
			height: 145px;
			border-radius: 10px;
			margin: 0 10px 10px 0;
			border: 1px solid #cdd0d6;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.icon-item {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer;
		}
		
	}
</style>