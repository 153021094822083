<template>
  <el-dialog
    :modelValue="colorDialogVisible"
    @close="$emit('update:colorDialogVisible', false)"
    @open="handleOpenColor"
    title="请选择颜色"
    center
  >
    <el-tabs v-model="state.colorActiveName" type="card" class="demo-tabs" stretch @tab-change="handleChangeColor">
      <el-tab-pane v-for="(item, index) in state.tab_list" :key="index" :label="item.label" :name="item.name">
        <el-checkbox-group v-model="state.color_select_list">
          <el-checkbox v-for="(items, subIndex) in item.children" :key="subIndex" :label="items.mtColor">
            <template #default>
              <span
                >{{ items.mtColor }}<span style="color: #1c9cf6">{{ items.nmColor }}</span></span
              >
            </template>
          </el-checkbox>
        </el-checkbox-group>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleSelectedColor">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { findPresaleColor, productColorList } from '@/api/productSale';
import { withDefaultsParams } from '@/common';
import { SALE_TYPE } from '@/common/const';
import { reactive } from 'vue';

const props = defineProps({
  colorDialogVisible: {
    type: Boolean,
    default: false
  },
  sale_type: {
    type: Number,
    default: SALE_TYPE.TODAY
  },
  sale_date: {
    type: String,
    default: ''
  },
  main_category: {
    type: String,
    default: ''
  },
  sub_categroy: {
    type: String,
    default: ''
  },
  keywords: {
    type: String,
    default: ''
  },
  grade: {
    type: String,
    default: ''
  },
  brand_code: {
    type: String,
    default: ''
  }
});

const state = reactive({
  colorActiveName: '单色',
  color_select_list: [],
  sale_type: props.sale_type,
  tab_list: [
    { label: '单色', name: '单色', children: [] },
    { label: '复色', name: '复色', children: [] },
    { label: '特别色', name: '特别色', children: [] },
    { label: '混色', name: '混色', children: [] }
  ]
});

const emit = defineEmits(['update:colorDialogVisible', 'update_color']);

const getColorList = withDefaultsParams({
  saleType: props.sale_type,
  colorType: ''
})(async params => {
  const {
    code,
    data: { records }
  } = await productColorList(params);
  const idx = state.tab_list.findIndex(item => item.name == state.colorActiveName);
  if (idx != -1) {
    state.tab_list[idx].children = records;
  }
});

const getPresaleColorList = withDefaultsParams({
  sale_date: props.sale_date,
  maim_category: props.main_category,
  sub_catetgory: props.sub_catetgory,
  keywords: props.keywords,
  brand_code: props.brand_code,
  grade: props.grade,
  color_type: state.colorActiveName
})(async params => {
  const { data } = await findPresaleColor(params);
  const idx = state.tab_list.findIndex(item => item.name == state.colorActiveName);
  if (idx != -1) {
    state.tab_list[idx].children = data.map(item => {
      return {
        mtColor: item.name,
        nmColor: item.count
      };
    });
  }
});

function handleSelectedColor() {
  emit('update_color', state.color_select_list.join(','));
  emit('update:colorDialogVisible', false);
}

function handleChangeColor(event) {
  console.log(event);
  if (props.sale_type == SALE_TYPE.AFTERNOON) {
    getPresaleColorList({
      sale_date: props.sale_date,
      maim_category: props.main_category,
      sub_catetgory: props.sub_catetgory,
      keywords: props.keywords,
      brand_code: props.brand_code,
      grade: props.grade,
      color_type: state.colorActiveName
    });
  } else {
    getColorList({
      colorType: state.colorActiveName,
      saleType: props.sale_type
    });
  }
}

function handleOpenColor(event) {
  state.color_select_list = [];
  state.colorActiveName = '单色';
  if (props.sale_type !== state.sale_type) {
    state.sale_type = props.sale_type;
    for (let i = 0; i < state.tab_list.length; i++) {
      const element = state.tab_list[i];
      element.children = [];
    }
  }
  if (props.sale_type == SALE_TYPE.AFTERNOON) {
    getPresaleColorList({
      sale_date: props.sale_date,
      maim_category: props.main_category,
      sub_catetgory: props.sub_catetgory,
      keywords: props.keywords,
      brand_code: props.brand_code,
      grade: props.grade,
      color_type: state.colorActiveName
    });
  } else {
    getColorList({
      colorType: state.colorActiveName,
      saleType: props.sale_type
    });
  }
}
</script>

<style lang="scss"></style>
