<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="订购详细信息" draggable width="1200px">
		<el-table :data="state.data" border style="width: 100%" show-summary :summary-method="getSummaries"
			v-loading="state.loading" empty-text="没有找到相关信息！"
			:header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
			<el-table-column prop="OrderTime" label="下单时间" align="center"></el-table-column>
			<el-table-column prop="Brand,ProductName,Grade" label="品牌|品名|等级" align="center">
				<template #default="scope">
					<span><span
							style="color:#38adff;">{{ scope.row.Brand }}</span>{{ scope.row.ProductName }}{{ scope.row.Grade }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="MainCategory" label="大类" align="center"></el-table-column>
			<el-table-column prop="SubCategory" label="小类" align="center"></el-table-column>
			<el-table-column prop="Color" label="颜色" align="center"></el-table-column>
			<el-table-column prop="ProductPrice" label="单价" align="center" width="70">
				<template #default="scope">
					<span>{{ scope.row.ProductPrice.toFixed(2) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="OrderCount" label="预定数量" align="center" width="70"></el-table-column>
			<el-table-column prop="OrderAmount" label="预定金额" align="center" width="70">
				<template #default="scope">
					<span>{{ scope.row.OrderAmount.toFixed(2) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="SaleType" label="类型 " align="center" width="70"></el-table-column>
			<el-table-column prop="Status" label="状态" align="center" width="70"></el-table-column>
		</el-table>
		
	</el-dialog>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted
	} from 'vue';
	import {
		webBookOrderDetailList
	} from '@/api/user';
	import moment from 'moment';
	
	//订单详情数据源
	const state = reactive({
		data: [], //表格数据
		loading: true
	})
	/**
	 * 获取账单查询列表
	 * @param {无}
	 */
	const bookOrderId = ref('')
	const getOrderDetailList = () => {
		state.loading = true;
		return webBookOrderDetailList({
			bookOrderId: bookOrderId.value
		}).then(res => {
			state.data = res.data.records.map(item => {
				return {
					...item
				}
			})
			state.loading = false;
		})

	}

	const dialogVisible = ref(false);
	const onChangeVisible = (Id) => {
		bookOrderId.value = Id
		dialogVisible.value = true;
		getOrderDetailList();
	};

	defineExpose({
		onChangeVisible
	});
	
	//自定义合计列，不自定义时会合计单价
	const getSummaries = (param) => {
		const {columns,data} = param
		const sums = []
		columns.forEach((column, index) => {
			if (index === 5) {
				sums[index] = '合计'
				return
			}
			if (column.property === 'OrderCount') {
				const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
				sums[index] = values.reduce((prev, curr) => {
					const value = Number(curr) // 将values中的每个值转换为number类型
					if (!isNaN(value)) {
						return prev + curr
					} else {
						return prev
					}
				}, 0)
			}else if (column.property === 'OrderAmount'){
				const values = data.map(item => (item[column.property])) // 把对应一列中的之全部取出，放到一个数组中
				sums[index] = values.reduce((prev, curr) => {
					const value = Number(curr) // 将values中的每个值转换为number类型
					if (!isNaN(value)) {
						return prev + curr
					} else {
						return prev
					}
				}, 0)
				sums[index] = sums[index].toFixed(2); //保留2位小数
			}else{
				sums[index] = ''
			}
		})
	
		return sums
	};
</script>

<style lang="scss" scoped>
</style>