<template>
	<el-dialog v-model="dialogVisible" title="在线投诉建议" width="900px" :draggable="true">
		<el-form :model="onlineMessageForm" ref="messageFormRef" label-position="top">
			<el-form-item label="目标类型">
				<el-radio-group v-model="complainType">
  			  <!-- <el-radio :label="1">留言</el-radio> -->
  			  <el-radio :label="2">建议</el-radio>
  			  <el-radio :label="3">投诉客户经理</el-radio>
  			</el-radio-group>
			</el-form-item>
			<el-form-item label="问题和意见" prop="complainText" :rules="[{ required: true, message: '请填写您的问题或功能意见' }]">
				<el-input v-model="onlineMessageForm.complainText" type="textarea" :autosize="{ minRows: 5 }" clearable
					placeholder="请填写您的问题或功能意见，感谢您的支持~" />
			</el-form-item>
			<el-form-item label="图片（选填，提供问题截图）">
				<div class="img-list" v-if="fileList.length > 0">
					<div class="img-item" v-for="(item, index) in fileList" :key="index" @click="handlePictureCardPreview(item.url)">
						<img :src="item.url">
						<div class="icon-item" @click.stop="handleRemove(index)">
							<el-icon :size="20"><CircleCloseFilled color="#fa3534" /></el-icon>
						</div>
					</div>
				</div>
				<el-upload v-if="fileList.length <= 9" action="https://up-z2.qiniup.com/" :data="uploadParams" list-type="picture-card" :format="['jpg','jpeg','png']" :show-file-list="false" :limit="9" 
				 :multiple="true" :on-success="onSuccessImg" :before-upload="beforeUpload">
					<el-icon><Plus /></el-icon>
				</el-upload>
				<el-dialog v-model="dialogVisibleImg">
    			<img w-full :src="dialogImageUrl" alt="Preview Image" />
  			</el-dialog>
			</el-form-item>
			<el-form-item label="联系方式" prop="memLinkMan">
				<el-input v-model="onlineMessageForm.memLinkMan" :maxlength="11" clearable style="width:320px;"
					placeholder="留下联系方式，会更快解决问题~" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="large" type="primary" @click="submitMessageForm(messageFormRef)">提交留言</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref, reactive } from "vue";
	import { Plus, CircleCloseFilled } from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	import { handlerImgUrl } from "@/common/index";
	import {
		getQiNiuYunToken
	} from '@/api/user'
	import {
		saveComplain
	} from '@/api/productSale'

	const emit = defineEmits(['update'])
	
	// 获取表单对象
	const messageFormRef = ref()
	const complainType = ref(2)
	const onlineMessageForm = reactive({
		complainText: '',
		memLinkMan: '',
		picUrl: ''
	});

	// 上传图片
	const uploadParams = reactive({ token: '', key: '' })
	const beforeUpload = async (request) => {
    // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
    // 上传之前设置上传参数,
    let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
		const { data: { token } } = await getQiNiuYunToken()
		uploadParams.token = token
		uploadParams.key = filename
  }
	// 删除图片
	const handleRemove = (index) => {
		fileList.value.splice(index, 1)
	}
	// 预览图片
	const dialogVisibleImg = ref(false)
	const dialogImageUrl = ref('')
	const handlePictureCardPreview = (event) => {
		dialogImageUrl.value = event
		dialogVisibleImg.value = true
	}
	const fileList = ref([])
	const onSuccessImg = (uploadFile) => {
		fileList.value.push({name: uploadFile.key, url: handlerImgUrl(uploadFile.key)})
	}
	
	// 表单校验函数
	const submitMessageForm = (formEl) => {
		if (!formEl) return
		formEl.validate(async (valid) => {
			if (valid) {
				if (fileList.value.length > 0) {
					onlineMessageForm.picUrl = fileList.value.map(item => item.name).join(',')
				}
				const { code } = await saveComplain({...onlineMessageForm, complainType: complainType.value})
				if (code == 0) {
					ElMessage.success('提交成功')
					dialogVisible.value = false
					emit('update', true)
				}
			} else {
				console.log('error submit!')
				return false
			}
		})
	}

	/**
	 * 弹窗显示
	 * @param {无}
	 */
	const dialogVisible = ref(false);
	const onChangeVisable = () => {
		fileList.value = []
		Object.keys(onlineMessageForm).forEach(key => onlineMessageForm[key] = '')
		dialogVisible.value = true;
	};

	defineExpose({
		onChangeVisable
	});
</script>

<style lang="scss" scoped>
	.img-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.img-item {
			position: relative;
			width: 145px;
			height: 145px;
			border-radius: 10px;
			margin: 0 10px 10px 0;
			border: 1px solid #cdd0d6;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.icon-item {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer;
		}
	}
</style>