<template>
	<!-- 导航 -->
	<my-header></my-header>
	
	<!-- 主体内容 -->
	<router-view></router-view>
</template>

<script setup>
	import { onMounted } from 'vue';
	import myHeader from '@/components/myHeader.vue'
	import { cartStore } from '@/store/cart';
	
	const cartInfo = cartStore()

	onMounted(async () => {
		await cartInfo.getCartList()  // 获取购物车列表
	})

</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to 
.fade-leave-from	{
	opacity: 1;
}
</style>