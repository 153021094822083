<template>
	<div class="title">物流信息</div>
	<!-- 搜索栏 -->
	<!-- <el-form ref="formRef" :model="searchForm" inline>
		<el-form-item label="标题：" prop="Title">
			<el-input v-model="searchForm.Title" placeholder="请输入标题" style="width:120px;" clearable @change="changeInputVal"></el-input>
		</el-form-item>
		<el-form-item label="发布时间：" prop="CreateTime">
			<el-date-picker v-model="searchForm.CreateTime" type="daterange" start-placeholder="开始时间"
				end-placeholder="结束时间" range-separator="至" :default-value="[new Date()]"
				@change="change(searchForm.CreateTime)" />
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="getArticleList">查询</el-button>
		</el-form-item>
	</el-form> -->
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！"  :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="Title" label="标题" align="center"></el-table-column>
		<el-table-column prop="CreateTime" label="发布时间" align="center">
			<template #default="scope">
				<span>{{ moment(scope.row.CreateTime).format('YYYY-MM-DD') }}</span>
			</template>
		</el-table-column>
		<el-table-column label="详情" align="center">
			<template #default="scope">
				<router-link target="_blank" :to="{path:'/articleDetails',query:{id:scope.row.Id}}" style="color:#38ADFF;">详情</router-link>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted
	} from 'vue';
	import {
		articleListByType
	} from '@/api/article';
	import moment from 'moment'
	onMounted(async () => {
		await initData();
	})

	// const searchForm = reactive({
	// 	Title: '',
	// 	CreateTime: '',
	// 	startTime: '',
	// 	endTime: ''
	// });
	//数据源
	const state = reactive({
		params: {
			articleType: 6,
			filters: {
				enable: true,
				join: 'and',
				conditions: []
			},
			limit: {
				enable: true,
				conditions: {
					pageLength: 15,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})

	
	// const changeInputVal=()=>{
	// 	getArticleList()
	// }
	
	// const change = (val) => {
	// 	if (val) {
	// 		const v = JSON.parse(JSON.stringify(val))
	// 		searchForm.startTime = moment(v[0]).format('YYYY-MM-DD')
	// 		searchForm.endTime = moment(v[1]).format('YYYY-MM-DD')
			
	// 	} else {
	// 		searchForm.startTime = ''
	// 		searchForm.endTime = ''
	// 		getArticleList()
	// 	}
		
	// }
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getArticleList()
	}
	/**
	 * 获取通知公告列表
	 * @param {无}
	 */
	const getArticleList = () => {
		//state.params.filters.conditions = [];
		state.loading = true
		// state.params.filters.conditions.push({
		// 	name: 'Title',
		// 	type: 'like',
		// 	value: searchForm.Title
		// }, {
		// 	name: 'CreateTime',
		// 	type: 'dateBetween',
		// 	value: '[\"' + moment(searchForm.startTime).format('YYYY-MM-DD') + '\",\"' + moment(
		// 		searchForm.endTime).format('YYYY-MM-DD') + '\"]'
		// })
		return articleListByType(state.params).then(res => {
			console.log('state.params', state.params)
			const {
				data: {
					filterAmount,
					records
				}
			} = res
			state.data = records.map(item => {
				return {
					...item
				}
			})
			state.total = filterAmount
			state.loading = false
		})
	}

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getArticleList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getArticleList()
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #3A3A3A;
		line-height: 55px;
	}
</style>