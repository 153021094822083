<template>
  <div v-show="show">
    <div class="u-flex-row-content">
      <el-button size="small" class="blue-btn u-m-b-5" @click="onFullPositiveValence">全正价提前提交</el-button>
      <div class="send-date" v-if="state.list.length > 0"
        >发货日期：{{ moment(state.list[0].SaleDate).format('MM-DD') }}
      </div>
    </div>
    <el-table
      :data="state.list"
      v-loading="state.loading"
      border
      empty-text="没有找到相关信息！"
      show-summary
      style="width: 100%"
      size="small"
      class="u-m-b-15"
    >
      <el-table-column label="品名" align="center" header-align="center">
        <template #default="scope">{{ scope.row.Brand + scope.row.ProductName + ' ' + scope.row.Grade }}</template>
      </el-table-column>
      <el-table-column label="单价" align="center" width="40">
        <template #default="scope">{{ scope.row.ProductPrice }}</template>
      </el-table-column>
      <el-table-column label="数量" align="center" prop="OrderCount">
        <template #default="scope">
          <el-input-number
            size="small"
            v-model="scope.row.OrderCount"
            :min="0"
            v-if="scope.row.CanModify == 1"
            @change="onEditOrder(scope.row)"
          ></el-input-number>
          <span v-else>{{ scope.row.OrderCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额" align="center" prop="OrderMoney">
        <template #default="scope">{{ scope.row.OrderMoney.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="佣金" align="center" prop="Commission">
        <template #default="scope">{{ scope.row.Commission.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="50">
        <template #default="scope">
          <span v-if="scope.row.CanModify == 1">
            <!-- <i style="font-size: 20px" class="iconfont icon-bianji" @click="onEditOrder(scope.row)"></i> -->
            <i style="font-size: 20px" class="iconfont icon-shanchu1" @click="onDeleteOrder(scope.row)"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="sum-box">
      <div v-if="state.totalize.ShippingTitle">
        运费方案：{{ state.totalize.ShippingTitle }}（{{ memberInfo.logistics }}-{{ memberInfo.addressCity }}）
      </div>
      <div v-if="state.totalize.PredictTitle">
        {{ [0, 2].includes(state.totalize.ShippingType) ? '预估重量' : '预估件' }}：{{ state.totalize.PredictTitle }}
      </div>
      <div v-if="state.totalize.MainTotalDeliveryFee">预估运费：{{ state.totalize.MainTotalDeliveryFee }}</div>
      <div v-if="state.totalize.MainTotalPackingFee">预估配货装箱费：{{ state.totalize.MainTotalPackingFee }}</div>
      <div v-if="state.totalize.TotalDiscount">折扣：{{ state.totalize.TotalDiscount }}</div>
      <div v-if="state.totalize.TotalGiftOuantity">赠送数量：{{ state.totalize.TotalGiftOuantity }}</div>
      <div v-if="state.totalize.MainOrderActualAmount"
        >预估金额：
        <span style="color: red; font-weight: bold">{{ state.totalize.MainOrderActualAmount }}</span>
      </div>
    </div>
    <!-- 提示 -->
    <div class="tips-box">
      <div class="tips"><text>订购提示：</text>点击上方对应按钮查看相应的订单。</div>
      <div class="tips"
        >如果您操作不慎订错货品，可以在订购该产品三分钟内点击<i class="iconfont icon-bianji"></i
        ><i class="iconfont icon-shanchu1"></i>来修改或取消该产品订单, 修改或取消后网站会返回该产品的订购金额。</div
      >
      <div class="tips">
        <text>重要提示：</text
        >停盘前的30分钟和订购超过三分钟的，修改和删除键将自动隐藏，不再支持更改，请您留意时间，下单前仔细考虑，感谢您的配合！
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, watch } from 'vue';
import moment from 'moment';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getPurchasedOrderList, updateOrderDetailSave, submitOrderAdvance } from '@/api/productSale';
import { userStore } from '@/store/user';

const userInfo = userStore();

const props = defineProps({
  memberInfo: {
    type: Object,
    default: {}
  },
  show: {
    type: Boolean,
    default: false
  }
});

onMounted(() => {
  console.log('进入现售订单列表');
  getPurchasedList();
});

onUnmounted(() => {
  console.log('cccccccc');
});

/**
 * 全正价提前提交
 * @param {无}
 */
const onFullPositiveValence = async () => {
  const { code } = await submitOrderAdvance();
  if (code == 0) {
    ElMessage.success('已提交');
    getPurchasedList();
    userInfo.getMemberInfo();
  }
};

/**
 * 取已购货单列表
 * @param {无}
 */
const state = reactive({
  loading: false,
  list: [],
  totalize: {}
});

const getPurchasedList = async () => {
  Object.keys(state.totalize).forEach(key => (state.totalize[key] = 0));
  const {
    data: { records }
  } = await getPurchasedOrderList();
  state.list = records;
  state.totalize = { ...state.totalize, ...[...records].shift() };
  state.loading = false;
};

/**
 * 修改订单
 * @param {object} event
 */
const onEditOrder = async event => {
  const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
  if (event.OrderCount == null || !reg.test(event.OrderCount)) {
    ElMessage.warning('数量不能为空或数量格式不对');
    return;
  }
  const params = { id: event.Id, orderCount: event.OrderCount };
  const { code } = await updateOrderDetailSave(params);
  if (code == 0) {
    ElMessage.success(`${event.OrderCount == 0 ? '删除成功' : '修改成功'}`);
    getPurchasedList();
    userInfo.getMemberInfo();
  } else {
    event.OrderCount -= 1;
  }
};

/**
 * 删除订单
 * @param {object} event
 */
const onDeleteOrder = event => {
  ElMessageBox.alert('确定删除该订单吗？', '提示', {
    type: 'warning',
    cancelButtonText: '取消',
    confirmButtonText: '确定'
  }).then(async () => {
    const params = { id: event.Id, orderCount: 0 };
    const { code } = await updateOrderDetailSave(params);
    if (code == 0) {
      ElMessage.success('删除成功');
      getPurchasedList();
      userInfo.getMemberInfo();
    }
  });
};

function updateData(params) {
  getPurchasedList();
}

defineExpose({
  updateData
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/same.scss';

.send-date {
  margin-left: 25%;
  color: red;
}
</style>
